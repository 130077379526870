import * as React from 'react'
import { AppContext } from '../contexts/AppContext'
import { RequestResponseTypes, validate } from './Common'
import { check } from './utilFunctions'
import { signInWithCustomToken } from "firebase/auth"
import { firebaseAuth } from '../firestore'

export const useProcessLoginResponse = () => {
    const {
        gSetLoggedIn,
        gSetFirstname,
        gSetLeadModeratorForSubjects,
        gSetHigherAuthorityLevel,
        gSetStatus,
        gSetMyId,
        gSetLastname,
        gSetLastAllComentsCheck,
        gSetLastMessagesCheck,
        gSetLastNotificationsCheck,
        gSetCountryId,
    } = React.useContext(AppContext)

    const processLoginResponse = async (loginResponse: RequestResponseTypes.Login__Response) => {
        check(
            validate(loginResponse.country, 'countryExistence') &&
            validate(loginResponse.status, 'userStatus') &&
            validate(loginResponse.firstname, 'firstname') &&
            validate(loginResponse.lastname, 'lastname') &&
            // loginResponse.leadModeratorForSubjects should be less or equal to 10 because of
            // firestore listener that is set on authority subjects for new comments
            loginResponse.leadModeratorForSubjects.length <= 10,
            'bRRefs7as6T6'
        )

        // sign in to firestore before continue.
        // read more about that on backend in file where firestoreCustomToken is created (if still exists 30.3.23.)
        await signInWithCustomToken(firebaseAuth, loginResponse.firestoreCustomToken)

        gSetFirstname(loginResponse.firstname)
        gSetLastname(loginResponse.lastname)
        gSetMyId(loginResponse.id)
        gSetStatus(loginResponse.status)
        gSetLeadModeratorForSubjects(loginResponse.leadModeratorForSubjects)
        gSetHigherAuthorityLevel(loginResponse.higherAuthorityLevel)
        gSetLastMessagesCheck(loginResponse.lastChecks.messages)
        gSetLastNotificationsCheck(loginResponse.lastChecks.notifications)
        gSetLastAllComentsCheck(loginResponse.lastChecks.all_comments_check)
        gSetCountryId(loginResponse.country)
        gSetLoggedIn(true)

        console.log('You are logged in')
    }

    return { processLoginResponse }
}
