import React from 'react'
import classNames from "classnames"
import { IsbnParsed } from '../utils/Common'

type Props = {
    allIsbns: IsbnParsed[]
    selectIsbn: (isbnParsed: IsbnParsed) => void
    selectedIsbn: IsbnParsed | undefined
}

export const SelectIsbnBox = ({ allIsbns, selectIsbn, selectedIsbn }: Props) => {
    const [searchIsbnValue, setSearchIsbnValue] = React.useState('')
    const [searchIsbnFocus, setSearchIsbnFocus] = React.useState(false)

    const renderSearchIsbnResults = () => {
        const filteredIsbns = allIsbns!.filter(it => {
            const searchValueInIsbn = `${it.isbn}`.indexOf(searchIsbnValue)
            const searchValueInTitle = it.bookTitle.indexOf(searchIsbnValue)

            return (searchValueInIsbn !== -1) || (searchValueInTitle !== -1)
        })

        if (filteredIsbns.length > 0) {
            const list = filteredIsbns.map((it, i) => {
                return (
                    <div
                        key={it.isbn}
                        className={classNames("isbn", { selected: selectedIsbn?.isbn === it.isbn })}
                        onMouseDown={() => selectIsbn(it)}
                        style={{
                            backgroundColor: i % 2 === 0 ? '#c7d9eb' : '#cfd9e3',
                            margin: '6px',
                            padding: '0 8px',
                        }}
                    >
                        <span>{it.bookTitle}</span> &nbsp; <span>(isbn: {it.isbn})</span>
                    </div>
                )
            })

            return <div className="search-isbns-results">
                {list}
            </div>
        } else {
            return <div className="search-isbns-results">
                No isbns
            </div>
        }
    }

    return <div className="search-card">
        <div className="input-box">
            <input
                autoComplete="off"
                type="text"
                placeholder={selectedIsbn ? `${selectedIsbn.bookTitle} (${selectedIsbn.isbn})` : "Pronadji knjigu"}
                onChange={e => setSearchIsbnValue(e.target.value)}
                value={searchIsbnValue}
                onFocus={() => setSearchIsbnFocus(true)}
                onBlur={() => setSearchIsbnFocus(false)}
                className="form-control"
            />
        </div>

        {searchIsbnFocus && renderSearchIsbnResults()}
    </div>
}