import React from 'react'
import ReactDOM from 'react-dom/client'
import 'ionicons/css/ionicons.css'
import 'font-awesome/css/font-awesome.css'
import './scss/all.scss'
import { App } from './App'
import { AppWithContext } from './contexts/AppContext'
import { MathJaxContext, MathJax3Config } from "better-react-mathjax"
import { BrowserRouter } from 'react-router-dom'

const config: MathJax3Config = {
  loader: {
    load: [
      "[tex]/html",
      "[tex]/cancel",
      "[tex]/color",
    ]
  },
  tex: {
    packages: {
      "[+]": [
        "html",
        "cancel",
        "color",
      ]
    },
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"]
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"]
    ]
  }
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppWithContext>
        <MathJaxContext version={3} config={config}>
          <App />
        </MathJaxContext>
      </AppWithContext>
    </BrowserRouter>
  </React.StrictMode>
)

// Old version.
// New version will render twice each time when app or new page is open:
// https://github.com/facebook/react/issues/24467#issuecomment-1113273743
// If on production also works like this (calling twice) => revert to old version.
// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <AppWithContext>
//         <MathJaxContext version={3} config={config}>
//           <App />
//         </MathJaxContext>
//       </AppWithContext>
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// )
