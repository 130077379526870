import React from "react"

// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
export const useClickOutside = (ref: React.RefObject<HTMLElement>, onClick: () => void) => {
    React.useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (!ref.current?.contains(event.target as Node)) {
                onClick()
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => {
            document.removeEventListener("click", handleClickOutside, true)
        }
    }, [ref])
}