import React from 'react'
import { renderStringWithLatex } from '../Contribute/LatexPart'
import { DifficultyString, EotPart } from './Common'
import { assertNever, formatTimeFull } from './utilFunctions'
import * as _ from "lodash"
import { Badge } from 'reactstrap'
import { useLocalLanguage } from './ServiceLocal'

type Props = {
    eotText: EotPart[]
    onClick: () => void
    timestamp?: number
    difficulty: number | undefined
    area: {
        areaName: string
        // index will be used for chosing area color
        areaIndex: number
    } | undefined
    exerciseOrderNumberInList: number | undefined
}

const renderEotPart = (eotPart: EotPart) => {
    if (eotPart.content.type === 'picture') {
        return <img
            src={eotPart.content.pictureUrl}
            alt="No, or bad image..."
            className='img-in-eot-text'
            style={{
                marginBottom: '8px',
                marginTop: '8px',
            }}
        />
    } else if (eotPart.content.type === 'text') {
        return renderStringWithLatex(eotPart.content.text)
    } else {
        assertNever(eotPart.content)
    }
}

const difficultyNumberToString = (difficulty: number): DifficultyString => {
    if (difficulty < 4) {
        return 'easy'
    } else if (4 <= difficulty && difficulty <= 7) {
        return 'medium'
    } else {
        return 'hard'
    }
}

const difficultyColors: Record<DifficultyString, string> = {
    easy: '#1CAF9A',
    medium: '#ffc34d',
    hard: '#ff584f',
}

const areaIndexColors: Map<number, string> = new Map([
    // numbers should not be skiped if adding new
    [0, '#ffad33'],
    [1, '#9999ff'],
    [2, '#79d2a6'],
    [3, '#df9fbe'],
    [4, '#66b3ff'],
    [5, '#ff5050'],
    [6, '#66cc66'],
    [7, '#ff4d94']
])

export const EotTextInList = ({
    eotText,
    onClick,
    timestamp,
    difficulty,
    exerciseOrderNumberInList,
    area,
}: Props) => {
    const { inLocalLanguage } = useLocalLanguage()

    const hasBadges = exerciseOrderNumberInList != null || difficulty != null || area != null

    return <div
        className="exercise_from_my_list"
        onClick={onClick}
        style={{ position: "relative" }}
    >
        {hasBadges && (
            <>
                {exerciseOrderNumberInList && <span className='pd-l-3 pd-r-3' style={{
                    backgroundColor: '#75b5f0',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    fontSize: "90%",
                    color: "white",
                }}>{exerciseOrderNumberInList}.</span>}
                {(difficulty != null || area != null) && <div style={{
                    display: "flex",
                    position: "absolute",
                    top: 0,
                    right: 0,
                }}>
                    {difficulty && <span className='mg-l-5 pd-l-5 pd-r-5' style={{
                        backgroundColor: difficultyColors[difficultyNumberToString(difficulty)],
                        color: 'white',
                        borderBottomLeftRadius: "3px",
                        borderBottomRightRadius: "3px",
                        fontSize: "90%",
                    }}
                    >{inLocalLanguage(difficultyNumberToString(difficulty))}</span>}
                    {area && <span className='mg-l-5 pd-l-5 pd-r-5' style={{
                        backgroundColor: areaIndexColors.get(area.areaIndex % areaIndexColors.size),
                        color: 'white',
                        borderBottomLeftRadius: "3px",
                        borderBottomRightRadius: "3px",
                        fontSize: "90%",
                    }}
                    >{area.areaName}</span>}
                </div>}
            </>
        )}
        <div
            className="exercise_from_my_list_inner"
            style={{
                marginTop: hasBadges ? "22px" : undefined,
            }}
        >
            {timestamp && <div>{formatTimeFull(timestamp)}</div>}
            {_.sortBy(eotText, (it) => it.order_number)
                .map(part => {
                    return <div className="text_div" key={part.id}>
                        {renderEotPart(part)}
                    </div>
                })}
        </div>
    </div>
}
