import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { queryServer } from '../utils/queryServer'
import { Container } from "../utils/Container"
import { RequestResponseTypes } from '../utils/Common'
import { Eot } from './Eot'
import { ENVIRONMENT_CALCULATED } from '../utils/constants'
import { Loader } from '../utils/Loader'

export const ExercisePage = () => {
    const [exercise, setExercise] = React.useState<RequestResponseTypes.GetExercise__Response | null>()

    const { exerciseId } = useParams()
    const [searchParams] = useSearchParams()

    const getExercise = async (exerciseId: string) => {
        const response = await queryServer<RequestResponseTypes.GetExercise__Request, RequestResponseTypes.GetExercise__Response>(
            '/getExercise',
            {
                exerciseId,
                notificationId: ENVIRONMENT_CALCULATED === 'prod' ? undefined : searchParams.get("notification") ?? undefined,
                openCommentId: ENVIRONMENT_CALCULATED === 'prod' ? undefined : searchParams.get("openCommentId") ?? undefined,
            },
        )
        setExercise(response)
    }

    React.useEffect(() => {
        if (exerciseId != null) {
            getExercise(exerciseId)
        } else {
            setExercise(null)
        }
    }, [])

    let content
    if (exercise === undefined) {
        content = <Loader color='primary' />
    } else if (exercise === null) {
        content = <h5>Error (probably bad ID)</h5>
    } else {
        content = <Eot
            kind="exercise"
            eotId={exercise.id}
            textSolution={exercise.textSolution}
            text_public={exercise.exercise_text_public}
            video_solution={exercise.video_solution?.videoMetadata ?? null}
            subjectId={exercise.subjectId}
            initialyQueriedComments={exercise.initialyQueriedComments}
        />
    }

    return <Container
        title="Exercise page"
        className="exercise-page"
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: false,
        }}
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
    >
        {content}
    </Container>
}
