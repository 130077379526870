import React from 'react'
import { EotPartAndRendered } from "./SolveEot"
import { nanoid } from 'nanoid'
import * as _ from "lodash"

type Props = {
    setNewParts: React.Dispatch<React.SetStateAction<EotPartAndRendered[]>>
}

export const AddFieldCard = ({ setNewParts }: Props) => {
    return <div>
        <button
            className="btn add-field btn-warning btn-teal mg-b-10"
            onClick={() => setNewParts(prev => prev.concat({
                id: nanoid(),
                content: {
                    type: 'text',
                    text: '',
                },
                order_number: (_.maxBy(prev, p => p.order_number)?.order_number ?? 0) + 100,
                renderRaw: true,
            }))}
        >
            Add text field
        </button>
        <button
            className="btn add-field btn-warning btn-teal mg-b-10"
            onClick={() => setNewParts(prev => prev.concat({
                id: nanoid(),
                content: {
                    type: 'picture',
                    pictureUrl: '',
                },
                order_number: (_.maxBy(prev, p => p.order_number)?.order_number ?? 0) + 100,
                renderRaw: true,
            }))}
        >
            Add image field
        </button>
    </div>
}
