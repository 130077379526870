import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Register } from './RegisterAndLogin/Register'
import { Login } from './RegisterAndLogin/Login'
import { ForgotPassword } from './RegisterAndLogin/ForgotPassword'
import { LandingPage } from './LandingPage'

export const NotLoggedIn = () => {
    return <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<NavigateTo to='/' />} />
    </Routes>
}

export const NavigateTo = ({ to }: { to: string }) => {
    return <Navigate to={to} />
}
