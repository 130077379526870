import React from 'react'
import defaultProfilePicture from './defaultProfilePicture.svg'

type Props = {
    userId: string
}

export function ProfileImage({ userId }: Props) {
    const errorHandler = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const target = e.target as HTMLImageElement
        target.onerror = null
        target.src = defaultProfilePicture
        return true
    }

    return <img
        // src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_project_id}.appspot.com/o/${userId}%2FprofilePicture%2F${userId}-profile-picture?alt=media&token=x`}
        src={`./defaultProfilePicture.svg`}
        onError={errorHandler}
        alt="profile image"
    />
}
