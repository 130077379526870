import React from 'react'
import { TheoryInformationsForGrade } from '../utils/Common'
import { useNavigate } from 'react-router-dom'
import { calculateKeywordsMap } from './FindExercise'
import { check } from '../utils/utilFunctions'
import { useLocalLanguage } from '../utils/ServiceLocal'
import { LAST_FILTERS } from './LastFilters'

type Props = {
    theory: TheoryInformationsForGrade
}

export const FindTheory = ({ theory }: Props) => {
    const { inLocalLanguage } = useLocalLanguage()

    const fakeAreaHasRealSubarea = (fakeAreaId: string): boolean => {
        for (const area of theory.areaOrganization) {
            if (area.parent_id === fakeAreaId) {
                if (area.assigned_theory != null) {
                    return true
                } else {
                    const recursiveResult = fakeAreaHasRealSubarea(area.id)
                    if (recursiveResult) {
                        return true
                    }
                }
            }
        }
        return false
    }

    const theoriesSortedAndMemoized = React.useMemo(() => {
        // NOTE: DON'T SHOW FAKE AREAS WITHOUT REAL SUBAREAS REGARDLESS
        // THERE IS SOMETHING IN TYPING_KEYWORDS OR NOT !!!
        return theory
            .areaOrganization
            // filter those areas that are either real, or fake that have real subarea
            .filter(area => area.assigned_theory != null || fakeAreaHasRealSubarea(area.id))
            // it is OK to sort in place because previous filter returns new array
            .sort((a, b) => a.order_number - b.order_number)
            // calculate keywords
            .map(area => ({
                ...area,
                keywords: area.assigned_theory == null ? null : calculateKeywordsMap(undefined, `${area.assigned_theory.keywords} ${area.area_name}`),
            }))
    }, [])

    const [filteredTheories, setFilteredTheories] = React.useState(theoriesSortedAndMemoized)

    // It is not clear how to organize search by keywords.
    // For now: Search by additional_keywords+area_name (take only real areas into account).
    // Ignore fake areas don't, i.e. show fake area only if it has any real subarea, and don't
    // include fake's areas names in search.
    const [typingKeywords, setTypingKeywords] = React.useState(LAST_FILTERS.theoryKeywords ?? '')

    React.useEffect(() => {
        const typedKeywordAndFrequency = [...calculateKeywordsMap(undefined, typingKeywords)]

        const newFilteredTheories = theoriesSortedAndMemoized
            .filter(area => {
                // If area is fake => return true now. Later (in next step), areas will be
                // filtered for those that are either real or fake with real subarea.
                if (area.assigned_theory == null) {
                    return true
                }

                // the area is "real", so it needs to have defined keywords
                check(area.keywords != null, 'fljhLt')

                // check if real theory passes keywords
                for (const [typedKeyword, typedFrequency] of typedKeywordAndFrequency) {
                    const frequencyInArea = area.keywords.get(typedKeyword)
                    if (frequencyInArea == null || frequencyInArea < typedFrequency) {
                        return false
                    }
                }

                return true
            })
            // now filter for those areas that are either real, or fake with real subarea
            .filter(area => area.assigned_theory != null || fakeAreaHasRealSubarea(area.id))

        setFilteredTheories(newFilteredTheories)
    }, [typingKeywords])

    const navigate = useNavigate()

    const renderAreaOrganization = () => {
        return filteredTheories.map(area => <div
            key={area.id}
            style={{ marginLeft: `${area.deep_level * 12}px` }}
        >
            <span style={{ marginRight: '20px' }}>
                <span
                    className={`teory-title ${area.assigned_theory != null ? 'real' : ''}`}
                    onClick={() => {
                        if (area.assigned_theory != null) {
                            navigate(`/theory/${area.assigned_theory.id}`)
                        }
                    }}
                >
                    {area.area_name}
                </span>
            </span>
        </div>)
    }

    return <div className='find-theory wrapper mg-t-20'>
        <div className='filters'>
            <div style={{ marginBottom: '38px' }}>
                <div style={{ marginBottom: "2px" }}>{inLocalLanguage("Keywords")}</div>
                <input
                    style={{ fontSize: '16px' }}
                    type="string"
                    placeholder={inLocalLanguage("Find_theory_by_keywords")}
                    value={typingKeywords}
                    className="form-control"
                    onChange={(e) => {
                        LAST_FILTERS.theoryKeywords = e.target.value
                        setTypingKeywords(e.target.value)
                    }}
                />
            </div>
        </div>
        <hr style={{ margin: '30px 0', borderWidth: "2px" }} />
        <div className='organization'>
            {renderAreaOrganization()}
        </div>
    </div>
}
