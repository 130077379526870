import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { RequestResponseTypes, UserBasics } from '../utils/Common'
import { queryServer } from '../utils/queryServer'
import { ModalContentProps } from '../utils/useModal'
import { check } from '../utils/utilFunctions'
import { SavedCollectionWithKeywords } from './useSavedCollectionsMetadata'
import queryString from 'query-string'
import { AppContext } from '../contexts/AppContext'
import { useLocalLanguage } from '../utils/ServiceLocal'

type Props = {
    collectionId: string
    collectionTitle: string
    setSavedSortedCollectionsAndOwner: React.Dispatch<React.SetStateAction<{
        allCollections_sorted: SavedCollectionWithKeywords[]
        owner: UserBasics
    } | undefined>>
    navigateToSavedCollectionsAfterDelete: boolean
}

export const DeleteCollectionModal = ({
    params,
    closeModal,
}: ModalContentProps<Props>) => {
    const {
        collectionId,
        collectionTitle,
        setSavedSortedCollectionsAndOwner,
        navigateToSavedCollectionsAfterDelete,
    } = params

    const { gMyId } = React.useContext(AppContext)

    const navigate = useNavigate()

    const { inLocalLanguage } = useLocalLanguage()

    const deleteCollection = async () => {
        await queryServer<RequestResponseTypes.DeleteCollection__Request, RequestResponseTypes.DeleteCollection__Response>(
            '/deleteCollection',
            { collectionId },
        )

        setSavedSortedCollectionsAndOwner(prev => {
            check(prev != null && prev.owner.id === gMyId)
            return {
                owner: prev.owner,
                allCollections_sorted: prev.allCollections_sorted.filter(it => it.id !== collectionId)
            }
        })

        if (navigateToSavedCollectionsAfterDelete) {
            navigate(`/savedCollections?${queryString.stringify({ userId: gMyId })}`)
        } else {
            closeModal()
        }
    }

    return <div className='responsive-modal-box' style={{ width: "540px" }}>
        <ModalHeader toggle={closeModal}>{inLocalLanguage("Delete")} {inLocalLanguage("Collection_u").toLowerCase()}</ModalHeader>
        <ModalBody>
            {inLocalLanguage("Delete")} "<span style={{ fontWeight: "bold" }}>{collectionTitle}</span>" {inLocalLanguage("Collection_u").toLowerCase()}?
        </ModalBody>
        <ModalFooter>
            <Button color="danger" onClick={deleteCollection}>{inLocalLanguage("Delete")}</Button>
            <Button color="secondary" onClick={closeModal}>{inLocalLanguage("Cancel")}</Button>
        </ModalFooter>
    </div>
}
