import React from 'react'
import { RequestResponseTypes, UserBasics } from '../utils/Common'
import { queryServer } from '../utils/queryServer'
import { calculateKeywordsMap } from '../HomePage/FindExercise'
import { check } from '../utils/utilFunctions'

export type SavedCollectionWithKeywords = {
    id: string
    last_updated_ts: number
    collection_title: string
    collection_description: string
    is_private: boolean
    keywords: Map<string, number>
}

export const useSavedCollectionsMetadata = (collectionsFromUser: string) => {
    const [typingKeywords, setTypingKeywords] = React.useState('')

    const [savedSortedCollectionsAndOwner, setSavedSortedCollectionsAndOwner] = React.useState<{
        allCollections_sorted: SavedCollectionWithKeywords[]
        owner: UserBasics
    }>()

    React.useEffect(() => {
        getSavedCollectionsMetadata(collectionsFromUser)
    }, [collectionsFromUser])

    const getSavedCollectionsMetadata = async (userId: string) => {
        const response = await queryServer<RequestResponseTypes.GetSavedCollectionsMetadata__Request, RequestResponseTypes.GetSavedCollectionsMetadata__Response>(
            '/getSavedCollectionsMetadata',
            { collectionsFromUser: userId },
        )

        setSavedSortedCollectionsAndOwner(
            {
                allCollections_sorted: response.savedCollectionsMetadata
                    .sort((a, b) => b.last_updated_ts - a.last_updated_ts)
                    .map(it => ({
                        ...it,
                        keywords: calculateKeywordsMap(undefined, `${it.collection_title} ${it.collection_description}`),
                    })),
                owner: {
                    id: response.userId,
                    firstname: response.userFirstname,
                    lastname: response.userLastname,
                },
            }
        )
    }

    const [sortedAndFilteredCollections, setSortedAndFilteredCollections] = React.useState<SavedCollectionWithKeywords[]>()

    React.useEffect(() => {
        if (savedSortedCollectionsAndOwner == null) {
            setSortedAndFilteredCollections(undefined)
            return
        }

        const typedKeywordAndFrequency = [...calculateKeywordsMap(undefined, typingKeywords)]

        const newSortedAndFilteredCollections = savedSortedCollectionsAndOwner
            .allCollections_sorted
            .filter(collection => {
                for (const [typedKeyword, typedFrequency] of typedKeywordAndFrequency) {
                    const frequencyInCollection = collection.keywords.get(typedKeyword)
                    if (frequencyInCollection == null || frequencyInCollection < typedFrequency) {
                        return false
                    }
                }

                return true
            })

        setSortedAndFilteredCollections(newSortedAndFilteredCollections)
    }, [typingKeywords, savedSortedCollectionsAndOwner])

    if (sortedAndFilteredCollections == null) {
        return null
    } else {
        check(savedSortedCollectionsAndOwner != null, '86DNN7QM')
        return {
            collectionsOwner: savedSortedCollectionsAndOwner.owner,
            totalSavedCollections: savedSortedCollectionsAndOwner.allCollections_sorted.length,
            sortedAndFilteredCollections,
            typingKeywords,
            setTypingKeywords,
            setSavedSortedCollectionsAndOwner,
        }
    }
}
