import React from 'react'
import classNames from "classnames"
import { useLocalLanguage } from '../utils/ServiceLocal'

type Props = {
    title: string
    className?: string
    children: React.ReactNode
    withFooter: {
        withFooter: boolean
        setFooterToBootom: boolean
    }
    whiteWrapper: {
        whiteWrapper: boolean
        expandWhiteWrapperToWholeScreen: boolean
    }
    // ol?: JSX.Element
}

export const Container = (props: Props) => {
    const { inLocalLanguage } = useLocalLanguage()

    const footer = props.withFooter.withFooter ? <div className="slim-footer">
        <div className="container">
            {/* <p>Lako je</p> */}
            <p>{inLocalLanguage('Contact')}: <a href="mailto:lakoje.info@gmail.com">lakoje.info@gmail.com</a></p>
        </div>
    </div> : null

    return <div className={classNames(`${props.className} slim-mainpanel`, {
        "set-footer-to-bottom": props.withFooter.setFooterToBootom,
    })}>
        {/* <div className="container"> */}
        {/* <div className="slim-pageheader" style={{ display: props.ol ? "flex" : "block" }}>
                {props.ol}
                <h6 className="slim-pagetitle" style={{ color: "#868ba1" }}>{props.title}</h6>
            </div> */}
        <div
            // className={props.sectionWrapper ? "section-wrapper expand-section-wrapper-to-min-whole-screen" : undefined}
            className={classNames("container", {
                "section-wrapper": props.whiteWrapper.whiteWrapper,
                "expand-section-wrapper-to-min-whole-screen": props.whiteWrapper.expandWhiteWrapperToWholeScreen,
            })}
            style={{ marginTop: '12px', marginBottom: '12px', borderRadius: "8px" }}
        >
            {props.children}
        </div>
        {/* </div> */}
        {footer}
    </div>
}