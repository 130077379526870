import React from "react"
import { queryServer } from "../utils/queryServer"
import { useNavigate } from 'react-router-dom'
import { RequestResponseTypes } from "../utils/Common"
import { EotTextInList } from "../utils/EotTextInList"

export const MyExerciseList = () => {
    const [data, setData] = React.useState<RequestResponseTypes.GetSolvedExercises__Response>()
    const [showLoadMoreBtn, setShowLoadMoreBtn] = React.useState(true)
    const [mySolutionsMetrics, setMySolutionsMetrics] = React.useState<RequestResponseTypes.GetMySolutionsMetrics__Response>()

    const navigate = useNavigate()

    const fetchData = async (offset: number) => {
        const response = await queryServer<RequestResponseTypes.GetSolvedExercises__Request, RequestResponseTypes.GetSolvedExercises__Response>(
            '/getSolvedExercises',
            { offset },
        )
        setData((prev) => {
            const map = new Map(prev != null ? prev.map(it => [it.id, it]) : [])
            response.forEach(it => {
                if (!map.has(it.id)) {
                    map.set(it.id, it)
                }
            })

            return [...map.values()].sort((a, b) => b.last_updated_ts - a.last_updated_ts)
        })
        if (response.length === 0) {
            setShowLoadMoreBtn(false)
        }
    }

    // !!!
    // TODO : This should be at higher level (at MySolvingsPage) in order to pass
    // values both to exercise and theories solutions
    const getMySolutionsMetrics = async () => {
        const response = await queryServer<RequestResponseTypes.GetMySolutionsMetrics__Request, RequestResponseTypes.GetMySolutionsMetrics__Response>(
            '/getMySolutionsMetrics',
            undefined,
        )
        setMySolutionsMetrics(response)
    }

    React.useEffect(() => {
        fetchData(0)
        getMySolutionsMetrics()
    }, [])

    let content
    if (data == null) {
        content = <h5>Please wait</h5>
    } else if (data.length === 0) {
        content = <h5>No data</h5>
    } else {
        content = <div>
            <h4 className="mg-10" style={{ color: 'green' }}>
                Total solved exercises: {mySolutionsMetrics == null ? "loading..." : mySolutionsMetrics.totalSolvedExercises}
            </h4>
            {data.map(exercise => {
                return <EotTextInList
                    eotText={exercise.exercise_text_public}
                    difficulty={exercise.difficulty}
                    area={undefined}
                    exerciseOrderNumberInList={undefined}
                    onClick={() => navigate(`/exercise/${exercise.id}`)}
                    timestamp={exercise.last_updated_ts}
                    key={exercise.id}
                />
            })}
            {showLoadMoreBtn && <span className="span-btn" onClick={() => {
                if (data != null) {
                    fetchData(data.length)
                }
            }}>Load more</span>}
        </div>
    }

    return content
}