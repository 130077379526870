//
// some common stuff for server and client
//

export const COUNTRIES = [
    {
        id: 'eng-test1',
        order_number: 111,
        country_in_english: 'England-1',
        country_in_local: 'England-1',
        language_in_english: 'English',
        language_in_local: 'English',
        timezone: 'Europe/London',
        allow_register_on_production: 0,
    },
    {
        id: 'eng-test2',
        order_number: 222,
        country_in_english: 'England-2',
        country_in_local: 'England-2',
        language_in_english: 'English',
        language_in_local: 'English',
        timezone: 'Europe/London',
        allow_register_on_production: 0,
    },
    {
        id: 'srb-test1',
        order_number: 333,
        country_in_english: 'Serbia1',
        country_in_local: 'Srbija1',
        language_in_english: 'Serbian',
        language_in_local: 'Srpski',
        timezone: 'Europe/Belgrade',
        allow_register_on_production: 0,
    },
    {
        id: 'eng-dev',
        order_number: 1111,
        country_in_english: 'England-dev',
        country_in_local: 'England-dev',
        language_in_english: 'English',
        language_in_local: 'English',
        timezone: 'Europe/London',
        allow_register_on_production: 0,
    },
    {
        id: 'srb-dev',
        order_number: 1222,
        country_in_english: 'Serbia-dev',
        country_in_local: 'Srbija-dev',
        language_in_english: 'Serbian',
        language_in_local: 'Srpski',
        timezone: 'Europe/Belgrade',
        allow_register_on_production: 0,
    },
    {
        id: 'srbija-001',
        order_number: 95,
        country_in_english: 'Serbia',
        country_in_local: 'Srbija',
        language_in_english: 'Serbian',
        language_in_local: 'Srpski',
        timezone: 'Europe/Belgrade',
        allow_register_on_production: 1,
    },
] as const

export const getLanguageInEnglishFromCountryId = (countryId: CountryId): LanguageInEnglish => {
    return COUNTRIES.find(it => it.id === countryId)!.language_in_english
}

export type CountryId = typeof COUNTRIES[number]['id']

export type LanguageInEnglish = typeof COUNTRIES[number]['language_in_english']

export const SERVER_ERROR = '__SERVER__ERROR__'

export const JWT_EXPIRED = '__JWT_EXPIRED__'
export const JWT_INVALID = '__JWT_INVALID__'

export const ACCOUNT_NOT_VALIDATED = "ACCOUNT_NOT_VALIDATED"
export const ACCOUNT_NOT_VALIDATED_SEPARATOR = "____"

export const TOO_MANY_SAVED_COLLECTIONS = '__TOO_MANY_SAVED_COLLECTIONS__'
export const TOO_MANY_SAVED_ITEMS_IN_COLLECTION = '__TOO_MANY_SAVED_ITEMS_IN_COLLECTION__'

// this function should be improved
export function validate(
    value: string,
    type: 'firstname' | 'lastname' | 'email' |
        'password' | 'countryExistence' | 'userStatus'
): boolean {
    const valueX = value.trim()
    switch (type) {
        case 'firstname': {
            return valueX.length > 0
        }
        case 'lastname': {
            return valueX.length > 0
        }
        case 'email': {
            const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return regex.test(valueX)
        }
        case 'password': {
            return valueX.length >= 8
        }
        case 'countryExistence': {
            return COUNTRIES.find(it => it.id === valueX) != null
        }
        case 'userStatus': {
            const value_ = valueX as UserStatus
            if (value_ === 'basic') {
                return true
            } else if (value_ === 'leadModerator') {
                return true
            } else {
                return false
            }
        }
    }
}

export type UserBasics = {
    id: string
    firstname: string
    lastname: string
}

export type TestInGrade = {
    testId: string
    orderNum: number
    title: string
    description: string
    exercises: {
        // exercise's order number needs to be unique in test
        orderNumber: number
        rules: {
            ruleId: string
            gradeAreaId: string
            difficulty: DifficultyString
            // sum of probabilities needs to be 100
            probability: number
        }[]
    }[]
}

export type CommentDoc = {
    id: string
    authorId: string
    authorFirstname: string
    authorLastname: string
    hasReplies: boolean
    created_ts: number
    last_updated_ts: number
    deleted_at_ts: number
    content: string
    notify_author_on_reply: boolean
    //
    // comment ON is more detailed than comment IN. Comment IN is mostly (maybe only) used for querinn
    // when user clicks on "show comments", or "show comment replies"
    //
    // comment_on and comment_in will almost always be the same. Except when the comment is on comment.
    // In that case, comment_on will be the id of the comment you commented on,
    // and comment_in will be the id of the top-level comment (the comment under which your comment will exist)
    comment_on_details: CommentOnDetails
    //
    // comment_in_id field will be used to query comments (e.g when click on "show comments", or "show comment replies")
    comment_in_id: string
    //
    // For now, comment_on_subject_id will be fulfilled only if the comment is on:
    // exercise_part, exercise_video, theory_part, theory_video (so, not for comment on comment).
    // this will be used for contributors to check comments for subjects they have authorities for.
    // this approach is maybe bad, but for now let it be.
    // maybe later fulfil for comment replies too
    // NEW (28. apr 2023.)::: comment_on_subject_id will be set even for comment replies
    comment_on_subject_id: string | null
    //
    //
    comment_on_preview: string
    // response to user is user's id to who the comment is intended for
    response_to_user: string | null
}

export function recordEntries<K extends string, V>(record: Record<K, V>): [K, V][] {
    return Object.entries(record) as [K, V][]
}

export type UserStatus = 'basic' | 'leadModerator'

export type PossibleAuthority = 'leadModerator'

export type PossibleHigherAuthorityLevel = 10

export type ExerciseOrTheory = 'exercise' | 'theory'

export type NotificationDetailsFor_ResponseToYourComment = {
    type: 'responseToYourComment'
    // NOTE: groupedById is "id" of MY comment (not the comment who caused notification). It should be the same as commentId.
    // groupedById field exists only becuse of firestore query in case when grouping is needed
    groupedById: string
    // all data about comment is about MY comment (not the comment who caused notification)
    commentId: string
    commentPreview: string
    commentInId: string // commentInId should be id of eotTextPart/eotVideo/topComment (24.03.2023.)
    commentOnDetails: CommentOnDetails
    // - first9UsersWhoGrouped can be fulfiling up to 9 users.
    // - nGroupings represent the total number of groupings (same user can cause grouping more than 1 time,
    //   and each of that cause will increase nGroupings by 1)
    first9UsersWhoGrouped: UserBasics[]
    over9UsersGrouped: boolean
    nGroupings: number
}

export type Stage = 'visible_dev' | 'visible_contributor' | 'visible'

export type SubjectBasics = {
    subjectId: string
    stage: Stage
    subjectDescription: string
    inLocal: string
    orderNumber: number
}

export type ExerciseInformationsForGrade = {
    areaOrganization: {
        areaId: string
        order_number: number
        deep_level: number
        parent_id: string | null
        area_name: string
    }[]
    exercises: {
        id: string
        publicText: EotPart[]
        additionalKeywords: string
        difficulty: number
        inAreaId: string
        avgOrderNum: number
        lastUpdatedTs: number
    }[]
}

export type TheoryInformationsForGrade = {
    areaOrganization: {
        id: string
        order_number: number
        deep_level: number
        parent_id: string | null
        area_name: string
        assigned_theory: {
            id: string
            keywords: string
        } | null
    }[]
}

export type GradeBasics = {
    gradeId: string
    stage: Stage
    gradeDescription: string
    inLocal: string
    orderNumber: number
}

export type SubjectWithGrades = SubjectBasics & {
    grades: GradeBasics[]
}

export type EotPartContent = {
    type: 'text'
    text: string
} | {
    type: 'picture'
    pictureUrl: string
}

export type EotPart = {
    id: string
    content: EotPartContent
    order_number: number
}

export type VideoMetadata = {
    type: 'youtube'
    videoId: string
}

export type IsbnAreaParsed = {
    id: string
    title: string
    orderNum: number
    parentId: string | null
    deepLevel: number
}

export type TopCommentOnDetails = {
    on: 'exercise_video'
    // onId is exercise video id
    onId: string
    exerciseId: string
} | {
    on: 'exercise_part'
    // onId is exercise's text solution part id
    onId: string
    exerciseId: string
} | {
    on: 'theory_video'
    onId: string
    theoryId: string
} | {
    on: 'theory_part'
    onId: string
    theoryId: string
}

export type CommentOnDetails = TopCommentOnDetails | {
    // - This is basically comment reply. Because the comment or comment reply is commented.
    //
    on: 'comment'
    onId: string
    // replyInCommentDetails je za komentar koji je "top level komentar", tj to je komentar
    // unutar koga ce se naci ovaj comment reply, kada se ide na "get comment replies".
    // dakle, replyInCommentDetails su informacije za top-level komentar (komentar u kome se
    // nalazi nas komentar). Npr, onId ce biti id od onoga sta je komentarisao top komentar.
    replyInCommentDetails: TopCommentOnDetails
    // topCommentId is the id of the top comment who "holds" the reply. To je id komentara
    // gde na koji ces, kada kliknes "get replies", dobiti ovaj reply. A replyInCommentDetails su
    // informacije vezane za komentar sa id-jem topCommentId
    // (mozda konfuzno sam ispisao, vrtim sve u krug mozda...ispravices)
    topCommentId: string
}

export type QueriedComment = {
    commentId: string
    authorId: string
    authorFirstname: string
    authorLastname: string
    lastUpdatedTs: number
    content: string
    hasReplies: boolean
    commentOn: CommentOnDetails
    commentInId: string
}

export type IsbnParsed = {
    isbn: number
    bookTitle: string
    areas: IsbnAreaParsed[]
}

export const _difficulty = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export type Difficulty = typeof _difficulty[number]

export type DifficultyString = 'easy' | 'medium' | 'hard'

export type ChatDoc_LastMessage = {
    id: string
    timeSent: number
    sentByUser: string
    preview: string
    // shouldBeSeenByUsers will be uset to determine for which users
    // the red dot should be set on the chat, when ChatsPage is opened
    // When user opens the chat, this should be update (delete user from the array)
    shouldBeSeenByUsers: string[]
}

export type ChatDoc = {
    chatId: string
    // max 10 members for now because of firestore limitation on .where(in)
    // members will hold the data about users who are in the chat. When someone leaves the
    // chat (in case of grouped chat (btw this doesn't exist yet 4.apr.2023.)), it will be deleted from here.
    // Also, when the new messsage is sent, availableForMembers & lastMessage.shouldBeSeenByUsers will be set to all user ids from members
    members: UserBasics[]
    // availableForMembers is used for handle case where user delete (or leave) chat
    // So, when user delete chat, he will not be in availableForMembers, and that chat
    // will not be shown in chats
    availableForMembers: string[]
    lastMessage: ChatDoc_LastMessage | null
    details: {
        type: 'private'
    } | {
        type: 'group'
        createdByUser: string
        groupName: string
    }
    // showMessagesAfterTs will show the time from which messages should be seen for each chat member
    // Initially, showMessagesAfterTs[userId] should be -1. For users that are added in group after some time,
    // showMessagesAfterTs[userId] should be the time they are added.
    // When user deletes chat, write that time in  showMessagesAfterTs[userId]
    //
    // kad user obrise cet, moci ce da se vrate poruke stare, cim se showMessagesAfterTs[userId] vrati na staru vrednost
    // i moci ce da se "hakuje" u smislu da kad obrises poruke, i posaljes request gde ce rucno da se namesti
    // showMessagesAfterTs[userId], query ce msm da ti vrati i stare poruke. Zato mozda da postoji
    // napomena kada se brisu poruke, da one i dalje postoje i da ce moci da se povrate.
    // Ali za nove usere npr kad se ubace u grupu, stare poruke nece moci da budu vidjene,
    // jer u MessageDoc postoji polje "availableToUsers" (mozda se drugacije zove), za koje ce da postoji rule u firestore
    showMessagesAfterTs: {
        [userId: string]: number
    }
    //
    // Inside Chat, "Message" subcollection will exist.
    // when reading Messages, user will be able to see only messages
    // that were created after showMessagesAfterTs[userId]
    // (in rules should be set allow read if message.createdAt > chat.showMessagesAfterTs[userId])
}

export type MessageFull = {
    type: 'basic'
    text: string
} | {
    type: ExerciseOrTheory
    eotId: string
    eotPreview: string
}

export type MessageDoc = {
    messageId: string
    fromUser: UserBasics
    messageFull: MessageFull
    timeSent: number
    // availableToUsers je polje koje sluzi da pomogne za queries. Za njega ce postojati rules u firestore
    // na osnovu kog ce se znati da li se moze videti poruka ili ne (i u query naravno mora da postoji to,
    // jer firestore rules nisu filteri). Kada user obrise poruke, i kada se ponovo vrati u chat,
    // stare poruke nece videti jer ce sa klijenta slati timestamp kada je obrisao poruke (showMessagesAfterTs[userId])
    // Ali ako se taj timestamp hardkodira na klijentu, user ce videti i stare poruke (ako se nalazi u availableToUsers)
    // (bar mislim da ce videti, ako dobro razumem...jos nisam probao)
    availableToUsers: string[]
}

export namespace RequestResponseTypes {
    //
    // register
    export type Register__Request = {
        firstname: string
        lastname: string
        email: string
        password: string
        countryId: CountryId
    }

    export type Register__Response = boolean

    //
    // login
    export type Login__Request = {
        email: string
        password: string
        // countryFromClientContext will be used for errors for users that don't exist in database
        countryIdFromClientContext: CountryId
    }

    export type Login__Response = {
        id: string
        firstname: string
        lastname: string
        status: UserStatus
        leadModeratorForSubjects: string[]
        higherAuthorityLevel: PossibleHigherAuthorityLevel | undefined
        lastChecks: {
            notifications: number
            messages: number
            all_comments_check: number
        }
        token: string
        firestoreCustomToken: string
        country: CountryId
    }

    //
    // login with token
    export type LoginWithToken__Request = undefined

    export type LoginWithToken__Response = Login__Response

    //
    // logout
    export type Logout__Request = undefined

    export type Logout__Response = boolean

    //
    // change password
    export type ChangePassword__Request = {
        currentPassword: string
        newPassword: string
    }

    export type ChangePassword__Response = {
        success: 'no'
        reason: 'Wrong password'
    } | {
        success: 'yes'
        token: string
    }

    //
    // save isbn
    export type SaveIsbn__Request = {
        isbnMetadata: {
            isbn: number
            book_title: string
            n_exercises: number | null
            book_author: string | null
            publisher: string | null
            n_pages: number | null
            additional_description: string | null
        }
        isbnAreas: {
            id: string
            area_name: string
            order_number: number
            parent_id: string | null
            deep_level: number
        }[]
    }

    export type SaveIsbn__Response = true

    //
    // save exercise
    export type SaveExercise__Helper1 = {
        difficulty: number
        solving_time_in_minutes: number
        subject_id: string
        exercise_text_public: EotPart[]
        additional_keywords: string
        solution: EotPart[]
        video_solution: VideoMetadata | null
        in_isbn: number
        in_area_id: string
        on_page: number
        order_number_in_book: number
    }

    export type SaveExercise__New = {
        action: 'save_new'
    } & SaveExercise__Helper1

    export type SaveExercise__Edit = {
        action: 'edit'
        exerciseId: string
    } & SaveExercise__Helper1

    export type SaveExercise__Delete = {
        action: 'delete'
        exerciseId: string
    }

    export type SaveExercise__Request = SaveExercise__New | SaveExercise__Edit | SaveExercise__Delete

    export type SaveExercise__Response = true

    //
    // save theory
    export type SaveTheory__Helper1 = {
        theory_text: EotPart[]
        title: string
        solving_time_in_minutes: number
        additional_description: string | null
        additional_keywords: string
        subject_id: string
        solution: EotPart[]
        video_solution: VideoMetadata | null
    }

    export type SaveTheory__New = {
        action: 'save_new'
    } & SaveTheory__Helper1

    export type SaveTheory__Edit = {
        action: 'edit'
        theoryId: string
    } & SaveTheory__Helper1

    export type SaveTheory__Delete = {
        action: 'delete'
        theoryId: string
    }

    export type SaveTheory__Request = SaveTheory__New | SaveTheory__Edit | SaveTheory__Delete

    export type SaveTheory__Response = true

    //
    // get subjects with private classes
    export type GetSubjectPrivateClasses_Request = undefined

    export type GetSubjectPrivateClasses_Response = {
        subjectId: string
        subjectOrderNumber: number
        subjectInLocal: string
    }[]

    //
    // get subject private classes professors
    export type GetSubjectProfessors__Request = {
        subjectId: string
    }

    export type GetSubjectProfessors__Response = {
        user_id: string
        first_name: string
        last_name: string
        about_me: string
    }[]

    //
    // get all isbns
    export type GetAllIsbns__Request = undefined

    export type GetAllIsbns__Response = {
        data: IsbnParsed[]
    }

    //
    // getSubjectsAuthority
    export type GetSubjectsAuthority__Request = undefined

    export type GetSubjectsAuthority__Response = {
        subjectId: string
        subjectInLocal: string
        authority: PossibleAuthority
    }[]

    //
    // getExerciseWithMetadata
    export type GetExerciseWithMetadata__Request = {
        exerciseId: string
    }

    export type GetExerciseWithMetadata__Response = {
        exerciseMetadata: {
            id: string
            created_by: string
            created_ts: number
            last_updated_ts: number
            difficulty: number
            solving_time_in_minutes: number
            subject_id: string
            exercise_text_public: EotPart[]
            additional_keywords: string
            // for now, isbn is not needed. Isbn areaId is enough for now
            isbn_area_id: string
            order_number_in_isbn: number
            on_page_in_isbn: number
        }
        video_solution: {
            id: string
            videoMetadata: VideoMetadata
        } | null
        textSolution: EotPart[]
    }

    //
    // getExercise
    export type GetExercise__Request = {
        exerciseId: string
        notificationId: string | undefined
        // openCommentId is when lead moderator clicks on comment from "All comments check page"
        openCommentId: string | undefined
    }

    export type GetExercise__Response = {
        id: string
        exercise_text_public: EotPart[]
        video_solution: {
            id: string
            videoMetadata: VideoMetadata
        } | null
        textSolution: EotPart[]
        subjectId: string
        initialyQueriedComments: [QueriedComment, QueriedComment[]][] | undefined // [topComment, it'sReplies][]
    }

    //
    // getMySolutionsMetrics
    export type GetMySolutionsMetrics__Request = undefined

    export type GetMySolutionsMetrics__Response = {
        totalSolvedExercises: number
    }

    //
    // getSolvedExercises
    export type GetSolvedExercises__Request = {
        offset: number
    }

    export type GetSolvedExercises__Response = {
        id: string
        created_by: string
        created_ts: number
        last_updated_ts: number
        difficulty: number
        solving_time_in_minutes: number
        subject_id: string
        exercise_text_public: EotPart[]
        additional_keywords: string
    }[]

    //
    // getSolvedTheories
    export type GetSolvedTheories__Request = undefined

    export type GetSolvedTheories__Response = {
        id: string
        created_by: string
        created_ts: number
        last_updated_ts: number
        theory_text: EotPart[]
        title: string
        solving_time_in_minutes: number
        additional_description: string | null
        additional_keywords: string
        subject_id: string
    }[]

    //
    // getTheoryWithMetadata
    export type GetTheoryWithMetadata__Request = {
        theoryId: string
    }

    export type GetTheoryWithMetadata__Response = {
        theoryMetadata: {
            id: string
            created_by: string
            created_ts: number
            last_updated_ts: number
            theory_text: EotPart[]
            title: string
            solving_time_in_minutes: number
            additional_description: string | null
            additional_keywords: string
            subject_id: string
        }
        video_solution: {
            id: string
            videoMetadata: VideoMetadata
        } | null
        textSolution: EotPart[]
    }

    //
    // getTheory
    export type GetTheory__Request = {
        theoryId: string
        notificationId: string | undefined
        openCommentId: string | undefined
    }

    export type GetTheory__Response = {
        id: string
        theory_text: EotPart[]
        video_solution: {
            id: string
            videoMetadata: VideoMetadata
        } | null
        textSolution: EotPart[]
        subjectId: string
        initialyQueriedComments: [QueriedComment, QueriedComment[]][] | undefined // [topComment, it'sReplies][]
    }

    //
    // getGradesForSubject
    export type GetGradesForSubject__Request = {
        subjectId: string
    }

    export type GetGradesForSubject__Response = {
        grades: {
            id: string
            grade_in_local: string
            order_number: number
            grade_description: string
        }[]
    }

    //
    // saveGradesForSubject
    export type SaveGradesForSubject__Request = {
        subjectId: string
        grades: {
            id: string
            grade_in_local: string
            order_number: number
            grade_description: string
            todo: 'delete' | 'save'
        }[]
    }

    export type SaveGradesForSubject__Response = GetGradesForSubject__Response

    //
    // getAllSubjectTheoriesMetadataOrigin
    export type GetAllSubjectTheoriesMetadataOrigin__Request = {
        subjectId: string
    }

    export type GetAllSubjectTheoriesMetadataOrigin__Response = {
        data: {
            id: string
            created_by: string
            created_ts: number
            last_updated_ts: number
            theory_text: EotPart[]
            title: string
            solving_time_in_minutes: number
            additional_description: string | null
            additional_keywords: string
            subject_id: string
        }[]
    }

    //
    // getAreaOrganizationForGrade_Exercise
    export type GetAreaOrganizationForGrade_Exercise__Request = {
        gradeId: string
    }

    export type GetAreaOrganizationForGrade_Exercise__Response = {
        data: {
            areaId: string
            order_number: number
            deep_level: number
            parent_id: string | null
            area_name: string
            isbnAreasAssigned: string[]
        }[]
    }

    //
    // saveAreaOrganizationForGrade_Exercise
    export type SaveAreaOrganizationForGrade_Exercise__Request = {
        gradeId: string
        areaOrganization: {
            areaId: string
            order_number: number
            deep_level: number
            parent_id: string | null
            area_name: string
            // handleAssignedIsbnAreas je za one oblasti gde samo isbnAreasAssigned treba da se cacne
            todo: 'save' | 'delete' | 'handleAssignedIsbnAreas'
            isbnAreasAssigned: {
                isbnAreaId: string
                todo: 'save' | 'delete'
            }[]
        }[]
    }

    export type SaveAreaOrganizationForGrade_Exercise__Response = GetAreaOrganizationForGrade_Exercise__Response

    //
    // getAreaOrganizationForGrade_Theory
    export type GetAreaOrganizationForGrade_Theory__Request = {
        gradeId: string
    }

    export type GetAreaOrganizationForGrade_Theory__Response = {
        data: {
            id: string
            order_number: number
            deep_level: number
            parent_id: string | null
            area_name: string
            assigned_theory_id: string | null
        }[]
    }

    //
    // saveAreaOrganizationForGrade_Theory
    export type SaveAreaOrganizationForGrade_Theory__Request = {
        gradeId: string
        areaOrganization: {
            id: string
            order_number: number
            deep_level: number
            parent_id: string | null
            area_name: string
            // handleAssignedTheoryArea je za one oblasti gde samo assigned_theory_id treba da se cacne
            todo: 'save' | 'delete' | 'handleAssignedTheoryArea'
            assigned_theory_id: null | {
                theoryId: string
                todo: 'save' | 'delete'
            }
        }[]
    }

    export type SaveAreaOrganizationForGrade_Theory__Response = GetAreaOrganizationForGrade_Theory__Response

    //
    // createTest
    export type CreateTest__Request = {
        testInGrade: string
        testTitle: string
        testDescription: string
        testOrderNumber: number
        exercises: {
            exerciseOrderNumber: number
            rules: {
                gradeAreaId: string
                difficulty: DifficultyString
                probability: number
            }[]
        }[]
    }

    export type CreateTest__Response = boolean

    //
    // getTestsForGrade
    export type GetTestsForGrade__Request = {
        gradeId: string
    }

    export type GetTestsForGrade__Response = {
        tests: {
            testId: string
            testTitle: string
            testDescription: string
            testOrderNumber: number
            exercises: {
                exerciseOrderNumber: number
                rules: {
                    gradeAreaId: string
                    difficulty: string
                    probability: number
                }[]
            }[]
        }[]
    }

    //
    // deleteTest
    export type DeleteTest__Request = {
        testId: string
    }

    export type DeleteTest__Response = boolean

    //
    // home
    export type Home__Request = undefined

    export type Home__Response = {
        type: 'no_data'
    } | {
        type: 'single_option'
        subject: SubjectBasics
        grade: GradeBasics
        exercise: ExerciseInformationsForGrade
        theory: TheoryInformationsForGrade
        exerciseTests: TestInGrade[] | undefined
    } | {
        type: 'multiple_options'
        subjectsAndGrades: SubjectWithGrades[]
    }

    //
    // getOrganizationForGrade
    export type GetOrganizationForGrade__Request = {
        gradeId: string
    }

    export type GetOrganizationForGrade__Response = {
        exercise: ExerciseInformationsForGrade
        theory: TheoryInformationsForGrade
        exerciseTests: TestInGrade[] | undefined
    }

    //
    // getSavedCollectionsMetadata
    export type GetSavedCollectionsMetadata__Request = {
        collectionsFromUser: string
    }

    export type GetSavedCollectionsMetadata__Response = {
        // user is the owner of the saved collections
        userId: string
        userFirstname: string
        userLastname: string
        savedCollectionsMetadata: {
            id: string
            last_updated_ts: number
            collection_title: string
            collection_description: string
            is_private: boolean
        }[]
    }

    //
    // saveInCollection
    export type SaveInCollection__Request = {
        save: {
            in: 'new'
            collection_title: string
            collection_description: string
            is_private: boolean
            itemsToSave: {
                itIs: ExerciseOrTheory
                id: string
            }[]
        } | {
            in: 'existing'
            collectionId: string
            itemsToSave: {
                itIs: ExerciseOrTheory
                id: string
            }[]
        }
    }

    export type SaveInCollection__Response = true

    //
    // savedCollection
    export type SavedCollection__Request = {
        collectionId: string
    }

    export type SavedCollection__Response = {
        message: 'ok'
        savedCollection: {
            collectionId: string
            created_by: UserBasics
            lastUpdatedTs: number
            collectionTitle: string
            collectionDescription: string
            isPrivate: boolean
            itemsInCollection: {
                itemId: string
                itIs: ExerciseOrTheory
                text: EotPart[]
                addedTs: number
            }[]
        }
    } | {
        message: 'private_from_other_user'
    } | {
        message: 'not_exist'
    }

    //
    // updateCollection
    export type UpdateCollection__Request = {
        collectionId: string
        isPrivate: boolean
        collectionTitle: string
        collectionDescription: string
    }

    export type UpdateCollection__Response = boolean

    //
    // deleteCollection
    export type DeleteCollection__Request = {
        collectionId: string
    }

    export type DeleteCollection__Response = boolean

    //
    // deleteCollectionItem
    export type DeleteCollectionItem__Request = {
        collectionId: string
        itemId: string
    }

    export type DeleteCollectionItem__Response = boolean

    //
    // getComments
    export type GetComments__Request = {
        inId: string
        // lastReadDocId is used for offset
        lastReadDocId: string | null
    }

    export type GetComments__Response = {
        comments: QueriedComment[]
    }

    //
    // setComment
    export type SetComment__Request = {
        content: string
        comment_on_details: CommentOnDetails
        comment_in_id: string
        comment_on_subject_id: string | null
        comment_on_preview: string
        response_to_user: string | null
    }

    export type SetComment__Response = QueriedComment

    //
    // updateLastCheck
    export type UpdateLastCheck__Request = {
        timestamp: number
        fieldToUpdate: 'notifications' | 'messages' | 'all_comments_check'
    }

    export type UpdateLastCheck__Response = number

    //
    // getNotifications
    export type GetNotifications__Request = {
        lastReadDocId: string | null
    }

    export type GetNotifications__Response = {
        readNotificationsToTs: number
        notifications: {
            id: string
            lastUpdatedTs: number
            notificationTypeAndDetails: NotificationDetailsFor_ResponseToYourComment
        }[]
    }

    //
    // checkChatExistence
    export type CheckChatExistence__Request = {
        members: UserBasics[]
        details: {
            type: 'private'
        } | {
            type: 'group'
            groupName: string
        }
    }

    export type CheckChatExistence__Response = {
        type: 'exists'
        chatId: string
        members: UserBasics[]
        details: {
            type: 'private'
        } | {
            type: 'group'
            createdByUser: string
            groupName: string
        }
        showMeMessagesAfterTs: number
        updateShouldBeSeenByUsers: boolean
    }

    //
    // findUsers
    export type FindUsers__Request = {
        firstname: string
        lastname: string
    }

    export type FindUsers__Response = {
        users: UserBasics[]
    }

    //
    // forgot password
    export type ForgotPassword__Request = {
        email: string
    }

    export type ForgotPassword__Response = {
        success: 'yes'
        email: string
    } | {
        success: 'no'
        reason: 'UNKNOWN_EMAIL'
    }

    //
    // userProfile
    export type UserProfile__Request = {
        userId: string
    }

    export type UserProfile__Response = {
        about: string
        id: string
        firstName: string
        lastName: string
    }

    //
    // updateAboutMe
    export type UpdateAboutMe__Request = {
        aboutMe: string
    }

    export type UpdateAboutMe__Response = {
        aboutMe: string
    }

    //
    // sendMessage
    export type SendMessage__Request = {
        type: 'knownChatId'
        // messageId is sent from client, because client should know the Id
        // because later when listener sees the message that is sent, it will change it's
        // status from "sending" to "ok". Otherwise message came from listener
        // will not know whether to override status of some message etc.
        // a klijent mora da zna otp kako ce da izgleda messageDoc i pre nego sto
        // mu server odgovori, jer odmah stavljas poruku u poruke (sa status loading).
        // Kad firestore vidi poruku, timestamp nece biti identicni, pa ce da override msg,
        // i mozda ce order da se promeni u porukama, ali ok je to za sada
        messageId: string
        messageFull: MessageFull
        // chatId can be known or not. If the message is sent from ThreadView, chatId will be always known
        // (i.e. in practice it should be always known, but in theory it can be unknown, i.e. backend should not have
        // problems in any case)
        // chatId will be unknown when sending entity from send entity
        // modal (sending exercise/theory/collection later(3.may2023.)) to the users that are found in the search
        // In that case, backend should check if there exist
        chatId: string
    } | {
        // this is the case (for now) when sending entities from SendEntityModal - if sending entity
        // to the user that was found in the search
        type: 'unknownChatId'
        messageFull: MessageFull
        sendToUser: UserBasics
    }

    export type SendMessage__Response = true

    //
    // updateChatDoc_ShouldBeSeenByUsers
    export type UpdateChatDoc_ShouldBeSeenByUsers__Request = {
        chatId: string
    }

    export type UpdateChatDoc_ShouldBeSeenByUsers__Response = true

    //
    // getNewerComments
    export type GetNewerComments__Request = {
        inId: string
        lastSeenCommentId: string
    }

    export type GetNewerComments__Response = {
        comments: QueriedComment[]
    }

    //
    // getAlreadySavedInCollections
    export type GetAlreadySavedInCollections__Request = {
        eotId: string
    }

    export type GetAlreadySavedInCollections__Response = {
        alreadySavedInCollections: string[]
    }
}