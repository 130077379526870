export function debounce<A extends Array<unknown>>(
	f: (...args: A) => Promise<void>,
	delay: number,
): (...args: A) => void {
	let timeout: NodeJS.Timeout
	let executeId = 0
	let awaiting: Promise<void> | null = null

	return (...args) => {
		const call = async () => {
			executeId++
			const id = executeId
			if (awaiting) {
				await awaiting
			}
			if (executeId === id) {
				awaiting = f(...args)
			}
		}

		const schedule = (delay: number) => {
			clearTimeout(timeout)
			timeout = setTimeout(async () => {
				await call()
			}, delay)
		}

		schedule(delay)
	}
}