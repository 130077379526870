import React from 'react'
import { RequestResponseTypes } from '../utils/Common'
import { queryServer } from '../utils/queryServer'
import { useUpdateLastCheck } from '../utils/useUpdateLastCheck'
import { check, nowSecondsUTC } from '../utils/utilFunctions'
import { NotificationsList } from './NotificationsList'

export const NotificationsDropdown = () => {
    const [notifications, setNotifications] = React.useState<RequestResponseTypes.GetNotifications__Response>()

    const [hasMoreNotifications, setHasMoreNotifications] = React.useState(false)

    React.useEffect(() => {
        getNotifications(null)
    }, [])

    const getNotifications = async (lastReadDocId: string | null) => {
        const notifications = await queryServer<RequestResponseTypes.GetNotifications__Request, RequestResponseTypes.GetNotifications__Response>(
            '/getNotifications',
            { lastReadDocId },
        )

        setNotifications(prev => ({
            readNotificationsToTs: notifications.readNotificationsToTs,
            notifications: (prev?.notifications ?? []).concat(notifications.notifications).sort((a, b) => b.lastUpdatedTs - a.lastUpdatedTs)
        }))

        // mod 6 because pagination is by 3 on backend. If change on backend, change here too !!!
        if (notifications.notifications.length > 0 && notifications.notifications.length % 6 === 0) {
            setHasMoreNotifications(true)
        } else {
            setHasMoreNotifications(false)
        }
    }

    let content
    if (notifications == null) {
        content = <h5>Please wait...</h5>
    } else {
        content = <NotificationsList
            notifications={notifications}
        />
    }

    return <div className="dropdown-menu dropdown-menu-right notifications-cm">
        <div className="dropdown-menu-header">
            <h6 className="dropdown-menu-title">Notifications</h6>
        </div>
        <div className="dropdown-list notifications-list-ccmm">
            {content}
            {hasMoreNotifications && <div className="dropdown-list-footer">
                <span onClick={() => {
                    check(notifications?.notifications != null && notifications.notifications.length > 0, 'hjdkshj77H')
                    getNotifications(notifications.notifications[notifications.notifications.length - 1].id)
                }}>
                    <i className="fa fa-angle-down"></i>Show Older Notifications
                </span>
            </div>}
        </div>
    </div>
}
