import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import { firebaseStorage } from "../firestore"

// maybe try catch should be added ?????!!!!!
export async function uploadPhoto(
    e: React.ChangeEvent<HTMLInputElement>,
    // folderPath is the path of the folder where the image is saved in, e.g. users/profile-photos
    folderPath: string,
    // photoId is the name of the photo
    photoId: string,
    onSuccess?: (imageUrl: string) => void,
) {
    if (e.target.files) {
        const file = e.target.files[0]
        const storageRef = ref(firebaseStorage, `${folderPath}/${photoId}`)
        const uploadResponse = await uploadBytes(storageRef, file)

        console.log("uploadResponse::: ", uploadResponse)

        const imageUrl = await getDownloadURL(uploadResponse.ref)

        console.log("imageUrl::: ", imageUrl)

        if (onSuccess != null) {
            onSuccess(imageUrl)
        }
    }
}
