import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { queryServer } from '../utils/queryServer'
import { Container } from "../utils/Container"
import { RequestResponseTypes } from '../utils/Common'
import { Eot } from './Eot'
import { ENVIRONMENT_CALCULATED } from '../utils/constants'
import { Loader } from '../utils/Loader'

export const TheoryPage = () => {
    const [theory, setTheory] = React.useState<RequestResponseTypes.GetTheory__Response | null>()

    const { theoryId } = useParams()
    const [searchParams] = useSearchParams()

    const getTheory = async (theoryId: string) => {
        const response = await queryServer<RequestResponseTypes.GetTheory__Request, RequestResponseTypes.GetTheory__Response>(
            '/getTheory',
            {
                theoryId,
                notificationId: ENVIRONMENT_CALCULATED === 'prod' ? undefined : searchParams.get("notification") ?? undefined,
                openCommentId: ENVIRONMENT_CALCULATED === 'prod' ? undefined : searchParams.get("openCommentId") ?? undefined,
            },
        )
        setTheory(response)
    }

    React.useEffect(() => {
        if (theoryId != null) {
            getTheory(theoryId)
        } else {
            setTheory(null)
        }
    }, [])

    let content
    if (theory === undefined) {
        content = <Loader color='primary' />
    } else if (theory === null) {
        content = <h5>Error (probably bad ID)</h5>
    } else {
        content = <Eot
            kind="theory"
            eotId={theory.id}
            textSolution={theory.textSolution}
            text_public={theory.theory_text}
            video_solution={theory.video_solution?.videoMetadata ?? null}
            subjectId={theory.subjectId}
            initialyQueriedComments={theory.initialyQueriedComments}
        />
    }

    return <Container
        title="Exercise page"
        className="exercise-page"
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: false,
        }}
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
    >
        {content}
    </Container>
}
