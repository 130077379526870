import React from 'react'

export const YoutubeVideo = ({ videoId }: { videoId: string }) => (
    <div className="video-responsive">
        <iframe
            width="427"
            height="240"
            src={`https://www.youtube.com/embed/${videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    </div>
)
