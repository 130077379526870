import { SERVER_ERROR } from './Common'
import { ENVIRONMENT_CALCULATED } from './constants'
import { assertNever } from './utilFunctions'

export const TOKEN_NAME = `htame2020-${ENVIRONMENT_CALCULATED}`

export async function queryServer<_Req_, _Res_>(path: string, params: _Req_): Promise<_Res_> {
    console.log(`[query ${path}, ${params}]`)

    const token = window.localStorage.getItem(TOKEN_NAME)
    let headers: HeadersInit = {
        'Content-Type': 'application/json',
    }
    if (token != null) {
        headers['authentication'] = `Bearer ${token}`
    }

    let url
    if (ENVIRONMENT_CALCULATED === 'dev') {
        url = "http://localhost:5001"
    } else if (ENVIRONMENT_CALCULATED === 'prod') {
        url = "https://serverxyz.lakoje.com"
    } else {
        assertNever(ENVIRONMENT_CALCULATED)
    }

    const response = await fetch(
        `${url}${path}`,
        {
            method: 'POST',
            headers,
            body: JSON.stringify({ req: params }),
        },
    )
    const data = await (await response.json()).data

    // this is bad, because data can be anything (null, string, number, object) !!!!!!
    // So, maybe change to SERVER_ERROR response be just a string SERVER_ERROR (ipak ne moze samo string,
    // zbog error message). Za sad neka ga ovako.
    if (data?.server_error === SERVER_ERROR) {
        console.log(`###### Error at ${path} ${params} query ######`)
        throw new Error(data.error_message)
    } else {
        return data as _Res_
    }
}
