import React from "react"
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { ModalContentProps } from "../utils/useModal"
import { RequestResponseTypes } from "../utils/Common"
import { queryServer } from "../utils/queryServer"

type Props = {
    testId: string
    testTitle: string
}

export const DeleteTestModal = ({
    params,
    closeModal,
}: ModalContentProps<Props>) => {
    const { testId, testTitle } = params

    const deleteTest = async () => {
        const result = await queryServer<RequestResponseTypes.DeleteTest__Request, RequestResponseTypes.DeleteTest__Response>(
            '/deleteTest',
            { testId },
        )

        if (result) {
            location.reload()
        }

        closeModal()
    }

    return <div className='responsive-modal-box' style={{ width: "480px" }}>
        <ModalHeader toggle={closeModal}>
            Delete test?
        </ModalHeader>
        <ModalBody>
            Are you want to delete test {testTitle}?
        </ModalBody>
        <ModalFooter>
            <Button color="danger" onClick={deleteTest}>Delete</Button>
            <Button color="secondary" onClick={closeModal}>Cancel</Button>
        </ModalFooter>
    </div>

}