import { ZonedDateTime, ZoneId, Instant } from '@js-joda/core'
import '@js-joda/timezone'

// to fix timezone !!!!!!!!!!!
export function formatTimeFull(ts: number): string {
    const zdt = ZonedDateTime.ofInstant(Instant.ofEpochSecond(ts), ZoneId.of('Europe/Belgrade'))
    return `${zdt.dayOfMonth()}.${zdt.monthValue()}.${zdt.year()}. ${zdt.hour()}:${zdt.minute()}`
}

export function check(x: boolean, message?: string): asserts x {
    if (!x) {
        throw new Error(message)
    }
}

export function checkNotNull<T>(x: T | null | undefined, message?: string): T {
    if (x == null) {
        throw new Error(message)
    }
    return x
}

export function assertNever(x: never, message?: string): never {
    throw new Error(message)
}

export function nowSecondsUTC(): number {
    return ZonedDateTime.now(ZoneId.UTC).toEpochSecond()
}

export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export function capitalizeFirstLetter(
    word: string,
    capitalizeFirstOtherSmall: boolean = true,
) {
    if (capitalizeFirstOtherSmall) {
        const wordLowercase = word.toLowerCase()
        return wordLowercase.charAt(0).toUpperCase() + wordLowercase.slice(1)
    } else {
        return word.charAt(0).toUpperCase() + word.slice(1)
    }
}
