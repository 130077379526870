import React from 'react'
import { assertNever } from '../utils/utilFunctions'
import { MathJax } from "better-react-mathjax"
import { EotPart } from '../utils/Common'

type Props = {
    eotPart: EotPart,
    toggle: () => void,
    deletePart: () => void,
}

type Curr = {
    t: 'str' | 'inlineLatex' | 'blockLatex'
    value: string
}

// This function is not good, and can be much better.
// Check it, and fix it later!!!!!!!!!!
// also change 'parseRegularAndLatexWords' when fix this, because it is
// just a copy of this function (and slightly changed)
export function renderStringWithLatex(str: string, color?: string) {
    const result = []

    const curr: Curr = {
        t: 'str',
        value: '',
    }

    for (let i = 0; i < str.length; i++) {
        const character = str[i]

        if (character !== "$" || (i > 0 && str[i - 1] === '\\')) {
            curr.value += character
        } else if (curr.t === 'str') {
            // add everything in result, reset the value, and start inlineLatex
            if (curr.value.length > 0) {
                result.push(<span key={result.length + 1}>{curr.value}</span>)
                curr.value = ''
            }
            curr.t = 'inlineLatex'
        } else if (curr.t === 'inlineLatex') {
            // the question is whether to close inlineLatex and render latex, or to start blockLatex
            if (curr.value.length > 0) {
                // add latex in result, reset the value, and start regular string
                result.push(<MathJax inline key={result.length + 1}>{`$${curr.value}$`}</MathJax>)
                curr.value = ''
                curr.t = 'str'
            } else {
                // start blockLatex
                // remove $
                curr.value = curr.value.slice(0, -1)
                curr.t = 'blockLatex'
            }
        } else if (curr.t === 'blockLatex') {
            if (str[i - 1] === '$') {
                // remove last $ from value
                // add latexBlock in result
                // reset value and t
                curr.value = curr.value.slice(0, -1)
                result.push(<MathJax key={result.length + 1}>{`$$${curr.value}$$`}</MathJax>)
                curr.t = 'str'
                curr.value = ''
            } else {
                curr.value += character
            }
        } else {
            assertNever(curr.t)
        }
    }

    if (curr.t === 'str' && curr.value.length > 0) {
        result.push(<span key={result.length + 1}>{curr.value}</span>)
    } else if (curr.t === 'inlineLatex') {
        result.push(<span key={result.length + 1}>${curr.value}</span>)
    } else if (curr.t === 'blockLatex') {
        result.push(<span key={result.length + 1}>$${curr.value}</span>)
    }

    return <span className="card-text">{result}</span>
}

// this is basicaly copy of renderStringWithLatex, just adjusted to what is needed
export function parseRegularAndLatexWords(strRaw: string): {
    // regularWords are all redular words concated with " ". Same with latex words
    regularWords: string
    latexWords: string
} {
    let regularWords: string = ''
    let latexWords: string = ''

    const curr: Curr = {
        t: 'str',
        value: '',
    }

    const str = strRaw.replace(/\n/g, " ").trim().toLowerCase()

    for (let i = 0; i < str.length; i++) {
        const character = str[i]

        if (character !== "$" || (i > 0 && str[i - 1] === '\\')) {
            curr.value += character
        } else if (curr.t === 'str') {
            // add everything in result, reset the value, and start inlineLatex
            if (curr.value.length > 0) {
                regularWords += `${curr.value} `
                curr.value = ''
            }
            curr.t = 'inlineLatex'
        } else if (curr.t === 'inlineLatex') {
            // the question is whether to close inlineLatex and render latex, or to start blockLatex
            if (curr.value.length > 0) {
                // add latex in result, reset the value, and start regular string
                latexWords += `${curr.value} `
                curr.value = ''
                curr.t = 'str'
            } else {
                // start blockLatex
                // remove $
                curr.value = curr.value.slice(0, -1)
                curr.t = 'blockLatex'
            }
        } else if (curr.t === 'blockLatex') {
            if (str[i - 1] === '$') {
                // remove last $ from value
                // add latexBlock in result
                // reset value and t
                curr.value = curr.value.slice(0, -1)
                latexWords += `${curr.value} `
                curr.t = 'str'
                curr.value = ''
            } else {
                curr.value += character
            }
        } else {
            assertNever(curr.t)
        }
    }

    if (curr.value.length > 0) {
        regularWords += `${curr.value} `
    }

    return {
        regularWords,
        latexWords,
    }
}

export const LatexPart = ({ eotPart, toggle, deletePart }: Props) => {
    let content
    if (eotPart.content.type === 'picture') {
        content = <img
            className='img-in-eot-solution'
            src={eotPart.content.pictureUrl}
            alt="No, or bad image..."
        />
    } else if (eotPart.content.type === 'text') {
        content = renderStringWithLatex(eotPart.content.text)
    } else {
        assertNever(eotPart.content)
    }

    return <div className="contributors-exercise-box rendered-latex-box">
        <span
            className="toggle-span"
            onClick={toggle}
        >
            Toggle
        </span>
        <span
            onClick={deletePart}
            className="delete-span"
        >
            Delete
        </span>
        <div>
            {content}
        </div>
    </div>
}
