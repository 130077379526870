import { initializeApp } from "firebase/app"
import {
    getFirestore,
    collection,
    CollectionReference,
    DocumentData,
} from 'firebase/firestore'
import "firebase/firestore"
import "firebase/storage"
import {
    getAuth,
    setPersistence,
    inMemoryPersistence,
    // browserSessionPersistence,
} from "firebase/auth"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
}

const app = initializeApp(firebaseConfig)

export const firestoreApp = getFirestore(app)
export const firebaseStorage = getStorage(app)
export const firebaseAuth = getAuth(app)
// FirestoreCustomToken will be used on client to auth on firestore.
// here is created custom firerbase token, that exp is max 1h, but it is enoguh
// to client signIn to firestore before it expires (at least it is for listeners.
// not sure what will happen when trying to send message, since token will be expired)
// I hope that it will be enough to just login, and to work everything
//
// and every time uses logs in, it will be signed in to firestore with new token
//
// when authenticate from client with custom token, each request on firestore from client will
// have filed 'auth', which will contain data about user.
//
// setPersistence to inMemoryPersistence (or browserSessionPersistence) in order to require
// user to login on firestore each time the app is loaded. Otherwise it will remain logged in.
setPersistence(firebaseAuth, inMemoryPersistence)

export const collectionRef = <T>(path: string): CollectionReference<T, DocumentData> => {
    return collection(firestoreApp, path) as CollectionReference<T, DocumentData>
}
