import React from 'react'
import { AppContext } from '../contexts/AppContext'
import { FindFriendModal } from './FindFriendModal'
import classNames from 'classnames'
import groupPicture from './groupPicture.svg'
import { getProfileImageURL, onProfileImageError } from "../Header/Header"
import { assertNever, check, checkNotNull, formatTimeFull, nowSecondsUTC } from "../utils/utilFunctions"
import { renderStringWithLatex } from '../Contribute/LatexPart'
import { ThreadView } from './ThreadView'
import { ChatDoc, ChatDoc_LastMessage, RequestResponseTypes, UserBasics } from '../utils/Common'
import { useUpdateLastCheck } from '../utils/useUpdateLastCheck'
import { debounce } from '../utils/debounce'
import { queryServer } from '../utils/queryServer'
import { useLastNChats } from './useLastNChats'
import { useSearchParams } from 'react-router-dom'
import { ENVIRONMENT_CALCULATED } from '../utils/constants'

export type ChatDocWithDefinedLastMessage = ChatDoc & {
    lastMessage: ChatDoc_LastMessage
}

export type ExistingChatSelected = {
    type: 'exists'
    chatId: string
    members: UserBasics[]
    details: {
        type: 'private'
    } | {
        type: 'group'
        createdByUser: string
        groupName: string
    }
    showMeMessagesAfterTs: number
    updateShouldBeSeenByUsers: boolean
}

type CheckExistenceChatSelected = {
    type: 'check_existence'
    members: UserBasics[]
    details: {
        type: 'private'
    } | {
        type: 'group'
        createdByUser: string
        groupName: string
    }
}

export type SelectedChat = ExistingChatSelected | CheckExistenceChatSelected

export function getChatName(
    myId: string,
    chatMembers: UserBasics[],
    details: {
        type: 'private'
    } | {
        type: 'group'
        groupName: string
    },
): string {
    if (details.type === 'group') {
        return details.groupName
    } else if (details.type === 'private') {
        check(chatMembers.length === 2, 'diposa895')
        const otherMember = chatMembers.find(member => member.id !== myId)!
        return `${otherMember.firstname} ${otherMember.lastname}`
    } else {
        assertNever(details)
    }
}

export function getChatProfilePicture(chatMembers: UserBasics[], chatType: 'private' | 'group', myId: string,): string {
    switch (chatType) {
        case 'group': {
            return groupPicture
        }
        case 'private': {
            check(chatMembers.length === 2, 'dsa77ux65')
            const otherMember = chatMembers.find(member => member.id !== myId)!
            return getProfileImageURL(otherMember.id)
        }
    }
}

let DEBOUNCE_CALL_ID = 0

export const ChatsPage = () => {
    if (ENVIRONMENT_CALCULATED === 'dev') {
        return <ChatsPageX />
    } else {
        return null
    }
}

const ChatsPageX = () => {
    const {
        gMyId,
        gFirstname,
        gLastname,
        gMessageNotification,
    } = React.useContext(AppContext)
    const [openNewChatModal, setOpenNewChatModal] = React.useState(false)

    const {
        chats,
        readMoreChats,
    } = useLastNChats()

    const [selectedAndProcessedChat, setSelectedAndProcessedChat] = React.useState<{
        selected: SelectedChat
        processed: ExistingChatSelected | 'loading'
    }>()

    const [searchParams] = useSearchParams()

    React.useEffect(() => {
        const userId = searchParams.get("userId")
        const firstname = searchParams.get("firstname")
        const lastname = searchParams.get("lastname")

        if (
            userId != null &&
            firstname != null &&
            lastname != null &&
            userId !== gMyId
        ) {
            selectChat(
                {
                    type: 'check_existence',
                    members: [{
                        firstname: gFirstname,
                        lastname: gLastname,
                        id: gMyId,
                    }, {
                        firstname,
                        lastname,
                        id: userId,
                    }],
                    details: {
                        type: 'private'
                    },
                }
            )
        }
    }, [])

    const calculateSelectedChatId = (): string | null => {
        if (selectedAndProcessedChat == null) {
            return null
        } else {
            if (selectedAndProcessedChat.selected.type === 'check_existence') {
                if (selectedAndProcessedChat.processed === 'loading') {
                    return null
                } else {
                    return selectedAndProcessedChat.processed.chatId
                }
            } else if (selectedAndProcessedChat.selected.type === 'exists') {
                if (selectedAndProcessedChat.processed != 'loading') {
                    check(
                        selectedAndProcessedChat.processed.chatId === selectedAndProcessedChat.selected.chatId,
                        'Rtyt561ww',
                    )
                }

                return selectedAndProcessedChat.selected.chatId
            } else {
                assertNever(selectedAndProcessedChat.selected)
            }
        }
    }

    // calculatedSelectedChatId is used only on chats list
    const calculatedSelectedChatId = calculateSelectedChatId()

    // check this!!!!! and check if the debounce function is ok!!!!!
    // one of the reasons for delay is to avoid unnecessary subscribe/unsubscribe if the user
    // quickly changes chats (check if this is ok, and the whole logic about messagec/chats!!!!!)
    //
    //
    // !!!!!!!!!!!!!!!!!! mozda (citaj OBAVEZNO i guess) treba da se doda neki ID da
    // ako scheduleSelectedChatProcessing awaits, and meantime
    // chat changes, prethodna funkcija ne treba da promeni stanje !!!!!! to je jako bitno !
    const scheduleSelectedChatProcessing = React.useCallback(debounce(async (selectedChat: SelectedChat, callId: number) => {
        console.log(`... [this was scheduled] process selected chat...`)
        if (selectedChat.type === 'check_existence') {
            // this is the case when user selects the chat from the modal (modal where the user can be found,
            // or group made). In this case we don't know if the chat exists, so we should check that on the backend
            //
            const result = await queryServer<RequestResponseTypes.CheckChatExistence__Request, RequestResponseTypes.CheckChatExistence__Response>(
                '/checkChatExistence',
                {
                    members: selectedChat.members,
                    details: selectedChat.details,
                },
            )

            // "callId+1" jer je DEBOUNCE_CALL_ID povecan za 1 odmah cim je pozvana funkcija
            if (callId + 1 === DEBOUNCE_CALL_ID) {
                setSelectedAndProcessedChat(prev => {
                    // mora prev da bude 'loading', jer se ovo radi iz debouncera, tj odlozeno je
                    check(prev?.processed === 'loading', '3iuydfbks6')
                    return {
                        ...prev,
                        processed: result,
                    }
                })
            }
        } else if (selectedChat.type === 'exists') {
            // this is the case when user chooses the existing chat from the chat list
            //
            // "callId+1" jer je DEBOUNCE_CALL_ID povecan za 1 odmah cim je pozvana funkcija
            if (callId + 1 === DEBOUNCE_CALL_ID) {
                setSelectedAndProcessedChat(prev => {
                    // mora prev da bude 'loading', jer se ovo radi iz debouncera, tj odlozeno je
                    check(prev?.processed === 'loading', 'mncxhh6Y')
                    return {
                        ...prev,
                        processed: selectedChat,
                    }
                })
            }
        } else {
            assertNever(selectedChat)
        }
    }, 1_500), [])

    const selectChat = (selectChat: SelectedChat) => {
        setSelectedAndProcessedChat({
            selected: selectChat,
            processed: 'loading',
        })
        // DEBOUNCE_CALL_ID++ in call means: call function with old DEBOUNCE_CALL_ID value,
        // and immediately increase DEBOUNCE_CALL_ID by 1
        scheduleSelectedChatProcessing(selectChat, DEBOUNCE_CALL_ID++)
    }

    const { updateLastMessagesCheck } = useUpdateLastCheck()

    // don't ever show red dot on message badge while on Chats page,
    // because the lastNChatsListener will show last chats immediately
    React.useEffect(() => {
        if (gMessageNotification) {
            // this is maybe not good, because it will not update
            // messages check never, unless  gMessageNotification === true
            // but I set this condition because of recursion (updateLastMessagesCheck will update gMessageNotification)
            console.log('%%% %%% update Last messages check %%% %%%')
            updateLastMessagesCheck(nowSecondsUTC())
        }
    }, [gMessageNotification])

    const renderChats = () => {
        if (chats?.chats == null) {
            return <h5>Please wait...</h5>
        } else if (chats.chats.length === 0) {
            return <h5>Still no chats</h5>
        } else {
            return chats.chats.map(chat => {
                return <div
                    className={classNames(
                        "media chat-left",
                        {
                            unread: chat.lastMessage.shouldBeSeenByUsers.includes(gMyId),
                            "selected-chat": chat.chatId === calculatedSelectedChatId,
                        }
                    )}
                    key={chat.chatId}
                    onClick={() => {
                        selectChat({
                            type: 'exists',
                            chatId: chat.chatId,
                            members: [...chat.members],
                            details: chat.details,
                            showMeMessagesAfterTs: checkNotNull(chat.showMessagesAfterTs[gMyId], 'IUhyY7w'),
                            updateShouldBeSeenByUsers: chat.lastMessage.shouldBeSeenByUsers.find(it => it === gMyId) != null,
                        })
                    }}
                >
                    <div className="media-left">
                        <img
                            src={getChatProfilePicture(chat.members, chat.details.type, gMyId)}
                            onError={onProfileImageError}
                            alt="chat image"
                        />
                        <span className="square-10 bg-success"></span>
                    </div>
                    <div className="media-bodyy">
                        <div>
                            <h6>{getChatName(gMyId, chat.members, chat.details)}</h6>
                            {renderStringWithLatex(chat.lastMessage.preview, 'black')}
                        </div>
                        <div className="div-with-red-point">
                            <span>{formatTimeFull(chat.lastMessage.timeSent)}</span>
                            {chat.lastMessage.shouldBeSeenByUsers.includes(gMyId) && <span className="unread-message-in-chat" />}
                        </div>
                    </div>
                </div>
            })
        }
    }

    return <div className="container container-messages with-sidebar messages-container" >
        <div className="messages-left">
            <div className="slim-pageheader">
                <h6 className="slim-pagetitle">Messages</h6>
                <div
                    className="messages-compose"
                    onClick={() => setOpenNewChatModal(!openNewChatModal)}
                >
                    <i className="icon ion-compose" />
                </div>
            </div>

            <div className="messages-list">
                {renderChats()}
                {readMoreChats != null && <span className='span-btn' onClick={readMoreChats}>
                    Load older chats
                </span>}
            </div>

            <div className="messages-left-footer">
                <button className="btn btn-slim btn-uppercase-sm btn-block">Load Older Messages</button>
            </div>
        </div>

        <ThreadView
            processedSelectedChat={selectedAndProcessedChat?.processed}
            myId={gMyId}
        />

        <FindFriendModal
            modalIsOpen={openNewChatModal}
            toggleModal={() => setOpenNewChatModal(!openNewChatModal)}
            selectChat={selectChat}
        />
    </div>
}
