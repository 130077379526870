import React from 'react'
import { Link } from 'react-router-dom'


export const LandingPage = () => {
    return <div className='my-landing-page'>
        <h1 className="logo-font" style={{ marginRight: 0, fontSize: '100px', color: 'rgb(27, 132, 231)' }}>
            Lako je
        </h1>
        <p style={{ fontSize: '18px' }}>
            Pripremi se za prijemni iz matematike!
        </p>
        <div style={{ display: 'flex', marginTop: '20px' }}>
            <Link
                className="btn btn-primary btn-signin"
                role="button"
                to="/register"
                style={{ marginRight: '20px' }}
            >
                Registruj se
            </Link>
            <Link
                className="btn btn-primary btn-signin"
                role="button"
                to="/login"
            >
                Uloguj se
            </Link>
        </div>
    </div>
}
