import React from 'react'
import {
    ModalHeader,
    ModalBody,
    Input,
    Label,
    ModalFooter,
    Button,
    FormGroup,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap'
import { queryServer } from "../utils/queryServer"
import { RequestResponseTypes } from '../utils/Common'
import { ModalContentProps } from '../utils/useModal'
import { useLocalLanguage } from '../utils/ServiceLocal'
import { MAX_COLLECTION_DESCRIPTION_LENGTH, MAX_COLLECTION_TITLE_LENGTH } from '../ExercisePage/SaveInCollection_Modal'

type Props = {
    collectionId: string
    currIsPrivate: boolean
    currCollectionTitle: string
    currCollectionDescription: string
    afterSave: (
        collectionId: string,
        newIsPrivate: boolean,
        newCollectionTitle: string,
        newCollectionDescription: string
    ) => void
}

export const EditCollectionModal = ({
    params,
    closeModal,
}: ModalContentProps<Props>) => {
    const {
        collectionId,
        currCollectionDescription,
        currCollectionTitle,
        currIsPrivate,
        afterSave,
    } = params

    const [newIsPrivate, setNewIsPrivate] = React.useState(currIsPrivate)
    const [newCollectionTitle, setNewCollectionTitle] = React.useState(currCollectionTitle)
    const [newCollectionDescription, setNewCollectionDescription] = React.useState(currCollectionDescription)

    const { inLocalLanguage } = useLocalLanguage()

    const updateCollection = async () => {
        await queryServer<RequestResponseTypes.UpdateCollection__Request, RequestResponseTypes.UpdateCollection__Response>(
            '/updateCollection',
            {
                collectionId,
                isPrivate: newIsPrivate,
                collectionTitle: newCollectionTitle,
                collectionDescription: newCollectionDescription,
            },
        )

        afterSave(
            collectionId,
            newIsPrivate,
            newCollectionTitle,
            newCollectionDescription,
        )

        closeModal()
    }

    return <div className='save-in-bucket-modal responsive-modal-box' style={{ width: "620px" }}>
        <ModalHeader toggle={closeModal}>{inLocalLanguage('Settings')}</ModalHeader>
        <ModalBody>
            <FormGroup style={{ position: 'relative' }}>
                <Label>{inLocalLanguage('Collection title')}</Label>
                <Input
                    type="text"
                    placeholder={inLocalLanguage("Enter title")}
                    onChange={e => setNewCollectionTitle(e.target.value.substring(0, MAX_COLLECTION_TITLE_LENGTH))}
                    value={newCollectionTitle}
                    style={{
                        border: newCollectionTitle.length === 0 ? "1px solid red" : undefined,
                        fontSize: "16px",
                    }}
                />
                {newCollectionTitle.length === MAX_COLLECTION_TITLE_LENGTH && <span style={{
                    color: 'orange',
                }}>{inLocalLanguage('Reached collection title maximum length')}</span>}
            </FormGroup>
            <FormGroup style={{ position: 'relative' }}>
                <Label>{inLocalLanguage('Collection description (optional)')}</Label>
                <Input
                    type="textarea"
                    placeholder={inLocalLanguage("Collection description (optional)")}
                    onChange={e => setNewCollectionDescription(e.target.value.substring(0, MAX_COLLECTION_DESCRIPTION_LENGTH))}
                    value={newCollectionDescription}
                    style={{
                        border: newCollectionDescription.length === MAX_COLLECTION_DESCRIPTION_LENGTH ? "1px solid red" : undefined,
                        fontSize: "16px",
                    }}
                />
                {newCollectionDescription.length === MAX_COLLECTION_DESCRIPTION_LENGTH && <span style={{
                    color: 'orange',
                }}>{inLocalLanguage('Reached collection description maximum length')}</span>}
            </FormGroup>
            {false && <FormGroup>
                <Label>{inLocalLanguage('Collection visibility')}</Label>
                <UncontrolledDropdown>
                    <DropdownToggle caret outline>
                        {newIsPrivate ? inLocalLanguage("Private collection") : inLocalLanguage("Public collection")}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem
                            onClick={() => setNewIsPrivate(true)}
                        >
                            {inLocalLanguage("Private collection")}
                        </DropdownItem>
                        <DropdownItem
                            onClick={() => setNewIsPrivate(false)}
                        >
                            {inLocalLanguage("Public collection")}
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </FormGroup>}
        </ModalBody>
        <ModalFooter>
            <Button
                onClick={updateCollection}
                // use == instead of ===, because currIsPrivate has 0 and 1 values, and newIsPrivate has boolean values;
                disabled={currIsPrivate == newIsPrivate
                    && currCollectionTitle === newCollectionTitle
                    && currCollectionDescription === newCollectionDescription
                    || newCollectionTitle === ''
                }
                color="success"
                style={{
                    borderColor: currIsPrivate == newIsPrivate
                        && currCollectionTitle === newCollectionTitle
                        && currCollectionDescription === newCollectionDescription
                        || newCollectionTitle === '' ? '#e9ecef' : undefined
                }}
            >
                {inLocalLanguage('Save')}
            </Button>
            <Button onClick={closeModal}>
                {inLocalLanguage('Cancel')}
            </Button>
        </ModalFooter>
    </div>
}
