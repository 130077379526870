import * as React from 'react'
import { AppContext } from '../contexts/AppContext'
import { JWT_EXPIRED, JWT_INVALID, RequestResponseTypes } from './Common'
import { queryServer, TOKEN_NAME } from './queryServer'
import { useProcessLoginResponse } from './useProcessLoginResponse'

export const useCheckLoginStatus = () => {
    const { gSetLoggedIn } = React.useContext(AppContext)

    const { processLoginResponse } = useProcessLoginResponse()

    const checkLoginStatus = async () => {
        try {
            const loginResponse = await queryServer<RequestResponseTypes.LoginWithToken__Request, RequestResponseTypes.LoginWithToken__Response>(
                '/loginWithToken',
                undefined,
            )

            await processLoginResponse(loginResponse)
        } catch (err: any) {
            // maybe should delete in some other cases too? (in any case where token is not verified should be deleted)????!!!!!!
            if (err.message === JWT_EXPIRED || err.message === JWT_INVALID) {
                window.localStorage.removeItem(TOKEN_NAME)
            }
            gSetLoggedIn(false)
        }
    }

    React.useEffect(() => {
        if (window.localStorage.getItem(TOKEN_NAME) == null) {
            // there is no token in local storage, no need to check login status
            gSetLoggedIn(false)
        } else {
            checkLoginStatus()
        }
    }, [])
}
