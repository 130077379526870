import React from 'react'
import { queryServer } from "../utils/queryServer"
import { useParams } from 'react-router-dom'
import { RequestResponseTypes } from '../utils/Common'
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap'
import { Container } from '../utils/Container'
import classNames from 'classnames'
import { SubjectAuthority_Grade } from './SubjectAuthority_Grade'
import { SubjectAuthority_Exercise } from './SubjectAuthority_Exercise'
import { SubjectAuthority_Theory } from './SubjectAuthority_Theory'
import { SubjectAuthority_Test } from './SubjectAuthority_Test'

const SubjectAuthorityX = ({ subjectId }: { subjectId: string }) => {
    const [activeTab, setActiveTab] = React.useState<'grade' | 'exercise' | 'theory' | 'tests'>('grade')

    const [gradesForSubject, setGradesForSubject] = React.useState<RequestResponseTypes.GetGradesForSubject__Response>()

    const getGradesForSubject = async () => {
        const result = await queryServer<RequestResponseTypes.GetGradesForSubject__Request, RequestResponseTypes.GetGradesForSubject__Response>(
            '/getGradesForSubject',
            { subjectId },
        )
        setGradesForSubject(result)
    }

    React.useEffect(() => {
        getGradesForSubject()
    }, [])

    if (gradesForSubject == null) {
        return <h3>please wait...</h3>
    }

    return <Container
        title="My solvings page"
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: true,
        }}
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
    >
        <Nav tabs>
            <NavItem>
                <NavLink
                    className={classNames("tabb-x", { active: activeTab === 'grade' })}
                    onClick={() => setActiveTab('grade')}
                >
                    Grades
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classNames("tabb-x", { active: activeTab === 'exercise' })}
                    onClick={() => setActiveTab('exercise')}
                >
                    Exercises
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classNames("tabb-x", { active: activeTab === 'theory' })}
                    onClick={() => setActiveTab('theory')}
                >
                    Theories
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classNames("tabb-x", { active: activeTab === 'tests' })}
                    onClick={() => setActiveTab('tests')}
                >
                    Tests
                </NavLink>
            </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
            <TabPane tabId="grade">
                <Row>
                    <Col sm="12">
                        <SubjectAuthority_Grade
                            gradesForSubject={gradesForSubject}
                            subjectId={subjectId}
                            setGradesForSubject={setGradesForSubject}
                        />
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId="exercise">
                <Row>
                    <Col sm="12">
                        <SubjectAuthority_Exercise
                            gradesForSubject={gradesForSubject}
                        />
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId="theory">
                <Row>
                    <Col sm="12">
                        <SubjectAuthority_Theory
                            subjectId={subjectId}
                            gradesForSubject={gradesForSubject}
                        />
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId="tests">
                <Row>
                    <Col sm="12">
                        <SubjectAuthority_Test
                            gradesForSubject={gradesForSubject}
                        />
                    </Col>
                </Row>
            </TabPane>
        </TabContent>
    </Container>
}

export const SubjectAuthority = () => {
    const { subjectId } = useParams()

    if (subjectId == null) {
        return <h4>Unknown subject ID</h4>
    } else {
        return <SubjectAuthorityX subjectId={subjectId} />
    }
}
