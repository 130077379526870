import React from 'react'
import { collectionRef } from "../firestore"
import { ExistingChatSelected, getChatName, getChatProfilePicture } from "./ChatsPage"
import { getProfileImageURL, onProfileImageError } from "../Header/Header"
import { assertNever, check, checkNotNull } from '../utils/utilFunctions'
import { MessageDoc, RequestResponseTypes } from '../utils/Common'
import { renderStringWithLatex } from '../Contribute/LatexPart'
import { queryServer } from '../utils/queryServer'
import { nanoid } from 'nanoid'
import { useNavigate } from 'react-router-dom'
import * as _ from "lodash"
import { DocumentData, getDocs, limit, orderBy, query, QueryDocumentSnapshot, startAfter, where } from 'firebase/firestore'
import { onSnapshot } from 'firebase/firestore'

type Props = {
    processedSelectedChat: ExistingChatSelected | 'loading' | undefined
    myId: string
}

type MessageWithStatus = MessageDoc & {
    status: 'ok' | 'error' | 'sending'
}

const LIMIT_1 = 12
const LIMIT_2 = 3

const useLastNMessagesListener = (
    myId: string,
    processedSelectedChat: ExistingChatSelected | "loading" | undefined,
    setChatAndMessages: React.Dispatch<React.SetStateAction<"loading" | {
        chat: ExistingChatSelected
        messages: MessageWithStatus[]
        lastMessage: {
            snapshot: QueryDocumentSnapshot<MessageDoc, DocumentData>
            timestamp: number
        } | null
    } | undefined>>
) => {
    // lastNMessagesSubscribe will be used just for the last n messages. The rest of messages will be read
    // with query, clasic pagination (directly to firestore probably)
    const lastNMessagesSubscribe = (chat: ExistingChatSelected) => {
        const myCollectionRef = collectionRef<MessageDoc>(`Chat/${chat.chatId}/Message`)
        const myQuery = query(
            myCollectionRef,
            where('availableToUsers', 'array-contains', myId),
            where('timeSent', '>', chat.showMeMessagesAfterTs),
            orderBy('timeSent', 'desc'),
            limit(LIMIT_1),
        )

        const unsubscribe = onSnapshot<MessageDoc, DocumentData>(
            myQuery,
            (querySnapshot) => {
                const docs_ = querySnapshot.docs

                const messages_ = docs_.map(it => it.data())

                setChatAndMessages(prev => {
                    const messagesMap = new Map<string, MessageWithStatus>()

                    if (prev != null && prev !== 'loading') {
                        prev.messages.forEach(prevMessage => messagesMap.set(prevMessage.messageId, prevMessage))
                    }

                    // override messages with status e.g. 'sending', and add new once
                    messages_.forEach(messageDoc => messagesMap.set(messageDoc.messageId, {
                        ...messageDoc,
                        status: 'ok',
                    }))

                    const messages = [...messagesMap]
                        .map(([msgId, msg]) => msg)
                        .sort((a, b) => a.timeSent - b.timeSent)

                    let lastMessageNeww: {
                        snapshot: QueryDocumentSnapshot<MessageDoc, DocumentData>
                        timestamp: number
                    } | null
                    if (messages.length < LIMIT_1 || messages.length === 0) {
                        // lastMessageNeww ce da sluzi za paginaciju, tako da ne treba u ovom slucaju
                        lastMessageNeww = null
                    } else {
                        // nije null jer messages.length nije 0 (znaci postoje dokumenta). Kansije odradi malo lepse taj uslov
                        const snapshot = checkNotNull(_.minBy(docs_, (it) => it.data().timeSent), 'os8a798xuiuhcixuc')
                        const timestamp = snapshot.data().timeSent

                        if (
                            prev == null ||
                            prev === 'loading' ||
                            prev.lastMessage == null ||
                            timestamp < prev.lastMessage.timestamp
                        ) {
                            lastMessageNeww = {
                                snapshot,
                                timestamp,
                            }
                        } else {
                            lastMessageNeww = prev.lastMessage
                        }
                    }

                    return {
                        chat,
                        messages,
                        lastMessage: lastMessageNeww,
                    }
                })

                console.log(`[--->>> --->>> %%% %%%] Set listener for Last n Messages for chat ${chat.chatId}...`)
            },
            (err) => {
                console.log('Error iu89Ujv')
                console.log(err)
            },
        )

        return () => {
            console.log(`[--->>> --->>> %%% %%%] Remove n Messages listener for chat ${chat.chatId}.`)
            unsubscribe()
        }
    }

    React.useEffect(() => {
        let unsubscribe: () => void

        if (processedSelectedChat == null) {
            // should be possible only at initial render. ONLY IF LATER
            // IS ALLOWED TO DESELECT THE CHAT (AFTER SELECTION), THEN THIS SHOULD BE POSSIBLE IN MANY CASES
            // BUT IF THAT IS CHANGED IN THE FUTURE, THIS WILL throw an error because of the check bellow (11.apr2023)
            console.log('[--->>> --->>>] data for last n messages listener is NULL, so dont subscribe to nothing <<<---')
            unsubscribe = () => {
                console.log('[--->>> --->>>] Unsubscribe in this case does nothing (because chat was NULL) <<<---')
            }
            // messages should be undefined already. check (11.apr.2023)
            setChatAndMessages(prev => {
                check(prev == null, 'oiu78iuIV')
                return undefined
            })
        } else if (processedSelectedChat === 'loading') {
            // should be the case always when changing the chat
            console.log('[--->>> --->>>] data for last n messages listener is LOADING, so dont subscribe to nothing <<<---')
            unsubscribe = () => {
                console.log('[--->>> --->>>] Unsubscribe in this case does nothing (because chat was LOADING) <<<---')
            }
            setChatAndMessages('loading')
        } else {
            // existing chat case
            // there are console.logs and setMessages in lastNMessagesSubscribe
            unsubscribe = lastNMessagesSubscribe(processedSelectedChat)
        }

        return unsubscribe
    }, [processedSelectedChat])
}

let id = 0

export const ThreadView = ({
    processedSelectedChat,
    myId,
}: Props) => {
    const [chatAndMessages, setChatAndMessages] = React.useState<{
        chat: ExistingChatSelected
        messages: MessageWithStatus[]
        lastMessage: {
            snapshot: QueryDocumentSnapshot<MessageDoc, DocumentData>
            timestamp: number
        } | null
    } | 'loading'>()

    const [messageText, setMessageText] = React.useState('')

    const scrollToBottom = () => {
        console.log('scroll to bottom')
        bottomRef.current?.scrollIntoView({
            behavior: 'smooth',
        })
    }

    React.useEffect(() => {
        if (chatAndMessages !== 'loading' && chatAndMessages != null) {
            scrollToBottom()
        }
    }, [chatAndMessages !== 'loading' && chatAndMessages != null])

    React.useEffect(() => {
        setMessageText('')
        id++
    }, [processedSelectedChat])

    // useLastNMessagesListener vodi racuna i o subscription i unsubscription i o
    // tome da li messages treba da budu loading ili undefined itd
    // (mozda ce trebati da se improve ovo)
    useLastNMessagesListener(
        myId,
        processedSelectedChat,
        setChatAndMessages,
    )

    // ONEMOGUCI DA SE pozove ova funkcija dva puta u isto vreme (dodaj "loadingMoreMessages") !!!!!!!!
    // readMoreMessages can go through backend (i.e. not directely through firestore)?
    const readMoreMessages = async (
        callId: number,
        chatId: string,
        showMeMessagesAfterTs: number,
        lastMessageSnapshot: QueryDocumentSnapshot<MessageDoc, DocumentData>,
    ) => {
        const myCollectionRef = collectionRef<MessageDoc>(`Chat/${chatId}/Message`)
        const myQuery = query(
            myCollectionRef,
            where('availableToUsers', 'array-contains', myId),
            where('timeSent', '>', showMeMessagesAfterTs),
            orderBy('timeSent', 'desc'),
            startAfter(lastMessageSnapshot),
            limit(LIMIT_2),
        )

        const snapshot = await getDocs(myQuery)

        const docs = snapshot.docs

        const messages: MessageWithStatus[] = docs.map(it => ({
            ...it.data(),
            status: 'ok',
        }))

        if (callId === id) {
            setChatAndMessages((prev) => {
                check(prev != null && prev !== 'loading', 'pcivx879f79diouYYG6')

                const lastMessageSnapshot = _.minBy(docs, (it) => it.data().timeSent) ?? null

                return {
                    chat: prev.chat,
                    lastMessage: lastMessageSnapshot == null ? null : {
                        snapshot: lastMessageSnapshot,
                        timestamp: lastMessageSnapshot.data().timeSent,
                    },
                    messages: prev.messages.concat(messages).sort((a, b) => a.timeSent - b.timeSent)
                }
            })
        } else {
            console.log('call id is not the same, so dont update messages !!!kdjsha6s76567asd576')
        }
    }

    const renderLoadOlderMessagesBtn = () => {
        if (chatAndMessages != 'loading' && chatAndMessages?.lastMessage != null) {
            const lastMessageSnapshot = chatAndMessages.lastMessage.snapshot
            return <span className='span-btn' onClick={() => {
                readMoreMessages(
                    id,
                    chatAndMessages.chat.chatId,
                    chatAndMessages.chat.showMeMessagesAfterTs,
                    lastMessageSnapshot,
                )
            }}>
                Load older messages
            </span>
        } else {
            return null
        }
    }

    const bottomRef = React.useRef<HTMLDivElement>(null)

    const navigate = useNavigate()

    const updateChatDoc_ShouldBeSeenByUsers = async (chatId: string) => {
        await queryServer<RequestResponseTypes.UpdateChatDoc_ShouldBeSeenByUsers__Request, RequestResponseTypes.UpdateChatDoc_ShouldBeSeenByUsers__Response>(
            '/updateChatDoc_ShouldBeSeenByUsers',
            { chatId },
        )
    }

    function renderMessage(
        message: MessageWithStatus,
        myId: string,
    ): JSX.Element {
        if (message.fromUser.id === myId) {
            if (message.messageFull.type === 'basic') {
                return <div className="media" key={message.messageId}>
                    <div className="media-body reverse">
                        <div className="msg">
                            <div className="eots-in-message-container">
                                {renderStringWithLatex(message.messageFull.text, 'gray')}
                            </div>
                        </div>
                    </div>
                    <img
                        onClick={() => navigate(`/profile/${message.fromUser.id}`)}
                        src={getProfileImageURL(message.fromUser.id)}
                        onError={onProfileImageError}
                        alt="profile image"
                        className="wd-50 rounded-circle"
                    />
                </div>
            } else if (message.messageFull.type === 'exercise') {
                const exerciseId = message.messageFull.eotId

                return <div className="media" key={message.messageId}>
                    <div className="media-body reverse">
                        <div className="msg">
                            <div className="eots-in-message-container">
                                [exercise] {message.messageFull.eotPreview}.
                                Click <span className='span-btn' onClick={() => navigate(`/exercise/${exerciseId}`)}>here</span> to open the exercise
                            </div>
                        </div>
                    </div>
                    <img
                        onClick={() => navigate(`/profile/${message.fromUser.id}`)}
                        src={getProfileImageURL(message.fromUser.id)}
                        onError={onProfileImageError}
                        alt="profile image"
                        className="wd-50 rounded-circle"
                    />
                </div>
            } else if (message.messageFull.type === 'theory') {
                const theoryId = message.messageFull.eotId

                return <div className="media" key={message.messageId}>
                    <div className="media-body reverse">
                        <div className="msg">
                            <div className="eots-in-message-container">
                                [theory] {message.messageFull.eotPreview}.
                                Click <span className='span-btn' onClick={() => navigate(`/theory/${theoryId}`)}>here</span> to open the theory
                            </div>
                        </div>
                    </div>
                    <img
                        onClick={() => navigate(`/profile/${message.fromUser.id}`)}
                        src={getProfileImageURL(message.fromUser.id)}
                        onError={onProfileImageError}
                        alt="profile image"
                        className="wd-50 rounded-circle"
                    />
                </div>
            } else {
                assertNever(message.messageFull.type)
            }
        } else {
            if (message.messageFull.type === 'basic') {
                return <div className="media" key={message.messageId}>
                    <div>
                        <img
                            onClick={() => navigate(`/profile/${message.fromUser.id}`)}
                            src={getProfileImageURL(message.fromUser.id)}
                            onError={onProfileImageError}
                            alt="profile image"
                        />
                        <p onClick={() => navigate(`/profile/${message.fromUser.id}`)}>
                            {message.fromUser.firstname} {message.fromUser.lastname}
                        </p>
                    </div>
                    <div className="media-body">
                        <div className="msg">
                            <div className="eots-in-message-container">
                                {renderStringWithLatex(message.messageFull.text, 'white')}
                            </div>
                        </div>
                    </div>
                </div>
            } else if (message.messageFull.type === 'exercise') {
                const eotId = message.messageFull.eotId

                return <div className="media" key={message.messageId}>
                    <div>
                        <img
                            onClick={() => navigate(`/profile/${message.fromUser.id}`)}
                            src={getProfileImageURL(message.fromUser.id)}
                            onError={onProfileImageError}
                            alt="profile image"
                        />
                        <p onClick={() => navigate(`/profile/${message.fromUser.id}`)}>
                            {message.fromUser.firstname} {message.fromUser.lastname}
                        </p>
                    </div>
                    <div className="media-body">
                        <div className="msg">
                            <div className="eots-in-message-container">
                                [exercise] {message.messageFull.eotPreview}.
                                Click <span className='span-btn' onClick={() => navigate(`/exercise/${eotId}`)}>here</span> to open the exercise
                            </div>
                        </div>
                    </div>
                </div>
            } else if (message.messageFull.type === 'theory') {
                const eotId = message.messageFull.eotId

                return <div className="media" key={message.messageId}>
                    <div>
                        <img
                            onClick={() => navigate(`/profile/${message.fromUser.id}`)}
                            src={getProfileImageURL(message.fromUser.id)}
                            onError={onProfileImageError}
                            alt="profile image"
                        />
                        <p onClick={() => navigate(`/profile/${message.fromUser.id}`)}>
                            {message.fromUser.firstname} {message.fromUser.lastname}
                        </p>
                    </div>
                    <div className="media-body">
                        <div className="msg">
                            <div className="eots-in-message-container">
                                [theory] {message.messageFull.eotPreview}.
                                Click <span className='span-btn' onClick={() => navigate(`/theory/${eotId}`)}>here</span> to open the theory
                            </div>
                        </div>
                    </div>
                </div>
            } else {
                assertNever(message.messageFull.type)
            }
        }
    }

    React.useEffect(() => {
        if (
            processedSelectedChat != null &&
            processedSelectedChat != 'loading' &&
            processedSelectedChat.updateShouldBeSeenByUsers === true
        ) {
            updateChatDoc_ShouldBeSeenByUsers(processedSelectedChat.chatId)
        }
    }, [processedSelectedChat])

    const sendMessageHandler = async (chatId: string) => {
        if (messageText.length > 0) {
            const response = await queryServer<RequestResponseTypes.SendMessage__Request, RequestResponseTypes.SendMessage__Response>(
                '/sendMessage',
                {
                    type: 'knownChatId',
                    chatId,
                    messageFull: {
                        type: 'basic',
                        text: messageText,
                    },
                    messageId: nanoid(),
                },
            )

            setMessageText('')
            scrollToBottom() // nece lepo da skroluje, jer ce tek kasnije da se vidi poruka... ali sredices nekad kasnije !!!!!
        } else {
            console.log('Nothing has been written yet...')
        }
        console.log('zavrsi sta sve treba dalje (popune stanje sa porukom, isprazni text itd.) dsa88cds7Y')
    }

    let content
    if (chatAndMessages == null) {
        content = <div className="message-header">
            <a href="" className="message-back"><i className="fa fa-angle-left"></i></a>
            <h4>Chat is not selected</h4>
        </div>
    } else if (chatAndMessages === 'loading') {
        content = <div className="message-header">
            <a href="" className="message-back"><i className="fa fa-angle-left"></i></a>
            <h3>Please wait...</h3>
        </div>
    } else {
        const { chat, messages } = chatAndMessages

        content = <>
            <div className="message-header">
                <a href="" className="message-back"><i className="fa fa-angle-left"></i></a>
                <div className="media">
                    <img
                        onClick={() => {
                            if (chat.details.type === 'private') {
                                navigate(`/profile/${checkNotNull(chat.members.find(it => it.id !== myId), '7bvd7hhb').id}`)
                            }
                        }}
                        src={getChatProfilePicture(chat.members, chat.details.type, myId)}
                        onError={onProfileImageError}
                        alt="chat image"
                    />
                    <div className="media-body">
                        <h6 onClick={() => {
                            if (chat.details.type === 'private') {
                                navigate(`/profile/${checkNotNull(chat.members.find(it => it.id !== myId), '7bvd7hhb').id}`)
                            }
                        }}>
                            {getChatName(myId, chat.members, chat.details)}
                        </h6>
                        <p>Last seen: 7 hours ago</p>
                    </div>
                </div>
                <div className="message-option">
                    <div className="d-none d-sm-flex">
                        <a href=""><i className="icon ion-ios-gear-outline"></i></a>
                    </div>
                    <div className="d-sm-none">
                        <a href=""><i className="icon ion-more"></i></a>
                    </div>
                </div>
            </div>
            <div className="message-body">
                <div className="media-list">
                    {renderLoadOlderMessagesBtn()}
                    {messages.map(message => {
                        return renderMessage(message, myId)
                    })}
                    <div ref={bottomRef} />
                </div>
            </div>
            <div className="message-footer">
                <div className="row row-sm">
                    <div className="col-9 col-sm-8 col-xl-9">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Type message..."
                            value={messageText}
                            onChange={e => setMessageText(e.target.value)}
                        />
                    </div>
                    <div className="col-3 col-sm-4 col-xl-3 tx-right">
                        <div className="d-none d-sm-block">
                            <div>
                                <i
                                    className="fa fa-send send-message-btn"
                                    onClick={() => sendMessageHandler(chat.chatId)}
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    return <div className="messages-right">
        {content}
    </div>
}