import React from 'react'
import { AppContext } from '../contexts/AppContext'
import { useClickOutside } from '../utils/useClickOutside'
import { NotificationsDropdown } from './NotificationsDropdown'

export const NotificationsIcon = () => {
    const { gNotification } = React.useContext(AppContext)

    const [showNotifications, setShowNotifications] = React.useState(false)

    const ref = React.useRef<HTMLDivElement>(null)

    useClickOutside(ref, () => setShowNotifications(false))

    return <div ref={ref} className="dropdown dropdown-c">
        <span onClick={() => setShowNotifications(!showNotifications)} className="header-notification" data-toggle="dropdown">
            <i className="icon ion-ios-bell-outline"></i>
            {gNotification && <span className="indicator red"></span>}
        </span>
        {showNotifications && <NotificationsDropdown />}
    </div>
}
