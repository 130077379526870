import React from 'react'
import { queryServer } from '../utils/queryServer'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from 'reactstrap'
import { EotPartAndRendered } from "./SolveEot"
import * as _ from "lodash"
import { check, checkNotNull } from '../utils/utilFunctions'
import { RequestResponseTypes, VideoMetadata } from '../utils/Common'
import { ModalContentProps } from '../utils/useModal'

export type SaveTheoryModalParams = {
    eotText: EotPartAndRendered[]
    eotSolution: EotPartAndRendered[]
    videoSolution: VideoMetadata | null
    editTheory: RequestResponseTypes.GetTheoryWithMetadata__Response | null
}

export const SaveTheoryModal = ({
    params,
    closeModal,
}: ModalContentProps<SaveTheoryModalParams>) => {
    const {
        eotSolution,
        eotText,
        videoSolution,
        editTheory,
    } = params

    const [subjectsAuthority, setSubjectsAuthority] = React.useState<RequestResponseTypes.GetSubjectsAuthority__Response>()

    const getSubjectsAuthority = async () => {
        const result = await queryServer<RequestResponseTypes.GetSubjectsAuthority__Request, RequestResponseTypes.GetSubjectsAuthority__Response>(
            '/getSubjectsAuthority',
            undefined,
        )

        setSubjectsAuthority(result)
    }

    React.useEffect(() => {
        getSubjectsAuthority()
    }, [])

    //
    // additional information down
    const [solvingTimeInMinutes, setSolvingTimeInMinutes] = React.useState<number>()
    const [title, setTitle] = React.useState('')
    const [additionalDescription, setAdditionalDescription] = React.useState('')
    const [additionalKeywords, setAdditionalKeywords] = React.useState('')
    const [subject, setSubject] = React.useState<{ subjectId: string, subjectInLocal: string }>()
    // additional information up
    //

    const [ready, setReady] = React.useState(false)

    React.useEffect(() => {
        if (subjectsAuthority != null) {
            // fulfill state if there is read data. This should be checked if it is ok n!!!!!
            if (editTheory != null) {
                setSolvingTimeInMinutes(editTheory.theoryMetadata.solving_time_in_minutes)
                setTitle(editTheory.theoryMetadata.title)
                setAdditionalDescription(editTheory.theoryMetadata.additional_description ?? '')
                setAdditionalKeywords(editTheory.theoryMetadata.additional_keywords)

                // find subject title
                const subject = subjectsAuthority.find(s => s.subjectId === editTheory.theoryMetadata.subject_id)
                check(subject != null, 'uj8uYhz')
                setSubject({
                    subjectId: subject.subjectId,
                    subjectInLocal: subject.subjectInLocal,
                })
            }

            setReady(true)
        }
    }, [subjectsAuthority != null])

    const okFulfilled =
        eotText.length > 0 &&
        (
            eotSolution.length > 0 ||
            videoSolution && videoSolution.videoId.length > 0
        ) &&
        title.length > 0 &&
        subject != null &&
        solvingTimeInMinutes != null && solvingTimeInMinutes > 0

    const saveTheory = async () => {
        if (
            eotText.length > 0 &&
            (
                eotSolution.length > 0 ||
                videoSolution && videoSolution.videoId.length > 0
            ) &&
            title.length > 0 &&
            subject != null &&
            solvingTimeInMinutes != null && solvingTimeInMinutes > 0
        ) {
            const data1: RequestResponseTypes.SaveTheory__Helper1 = {
                theory_text: eotText,
                title,
                solving_time_in_minutes: solvingTimeInMinutes,
                additional_description: additionalDescription,
                additional_keywords: additionalKeywords,
                subject_id: subject.subjectId,
                solution: eotSolution,
                video_solution: videoSolution && videoSolution.videoId.length > 0 ? videoSolution : null,
            }

            let request: RequestResponseTypes.SaveTheory__Request
            if (editTheory != null) {
                request = {
                    ...data1,
                    action: 'edit',
                    theoryId: editTheory.theoryMetadata.id,
                }
            } else {
                request = {
                    ...data1,
                    action: 'save_new',
                }
            }

            await queryServer<RequestResponseTypes.SaveTheory__Request, RequestResponseTypes.SaveTheory__Response>(
                '/saveTheory',
                request,
            )

            // reload just in case so all old data will be removed
            location.reload()
        } else {
            console.log('Something not OK XjggfrR5')
        }
    }

    let content
    if (!ready) {
        content = <h2>Please wait..</h2>
    } else {
        content = <>
            <ModalHeader toggle={closeModal}>Additional info</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label>Solving time in minutes</Label>
                        <Input
                            type="number"
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            value={solvingTimeInMinutes ?? "Solving time in minutes..."}
                            onChange={e => {
                                // bad checks. Should be fixed in the future!!!
                                if (solvingTimeInMinutes == null && e.target.value === '0') {
                                    setSolvingTimeInMinutes(undefined)
                                } else if (e.target.value.length > 0) {
                                    setSolvingTimeInMinutes(+e.target.value)
                                } else {
                                    setSolvingTimeInMinutes(undefined)
                                }
                            }}
                            placeholder="Solving time in minutes..."
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Subject</Label>
                        <UncontrolledDropdown>
                            <DropdownToggle caret outline>
                                {subject?.subjectInLocal ?? 'Choose a subject'}
                            </DropdownToggle>
                            <DropdownMenu>
                                {checkNotNull(subjectsAuthority, 'jsuuja7').map((subject, index) => <DropdownItem
                                    key={index}
                                    onClick={() => setSubject(subject)}
                                >
                                    {subject.subjectInLocal}
                                </DropdownItem>)}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </FormGroup>
                    <FormGroup>
                        <Label>Title (this is ultra short explanation of what is explained in theory)</Label>
                        <Input
                            type="textarea"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Additional keywords (additional_keywords will be calculated for searching when users type keywords)</Label>
                        <Input
                            type="textarea"
                            value={additionalKeywords}
                            onChange={(e) => setAdditionalKeywords(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Additional description (this is something that contributor wants to add (anything bla bla). It will not be visible for users neither in search nor shown)</Label>
                        <Input
                            type="textarea"
                            value={additionalDescription}
                            onChange={(e) => setAdditionalDescription(e.target.value)}
                        />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                {editTheory != null && 'NOTE: This will EDIT existing theory'}
                <Button
                    onClick={saveTheory}
                    disabled={!okFulfilled}
                >
                    Save
                </Button>
            </ModalFooter>
        </>
    }

    return <div className="save-exercise-modal responsive-modal-box" style={{ width: "980px" }} >
        {content}
    </div>
}
