import React from 'react'
import {
    FormGroup,
    Label,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from 'reactstrap'
import { RequestResponseTypes } from '../utils/Common'
import { queryServer } from '../utils/queryServer'
import * as _ from "lodash"
import { CreateTest } from './SubjectAuthority_CreateTest'
import { assertNever, check } from '../utils/utilFunctions'
import { GetTests } from './SubjectAuthority_GetTests'

type Props = {
    gradesForSubject: RequestResponseTypes.GetGradesForSubject__Response
}

export type AreaOrganizationForGrade = {
    areaId: string
    order_number: number
    deep_level: number
    parent_id: string | null
    area_name: string
}[]

export const SubjectAuthority_Test = ({ gradesForSubject }: Props) => {
    const [selectedGrade, setSelectedGrade] = React.useState<{ id: string, grade_in_local: string }>()
    const [gradeAreaOrganization, setGradeAreaOrganization] = React.useState<AreaOrganizationForGrade>()
    const [selectedGradeArea, setSelectedGradeArea] = React.useState<{ areaId: string, area_name: string }>()
    const [existingOrNewTest, setExistingOrNewTest] = React.useState<'existing tests' | 'new test'>()

    const getAreaOrganizationForGrade = async (gradeId: string) => {
        const organization = await queryServer<RequestResponseTypes.GetAreaOrganizationForGrade_Exercise__Request, RequestResponseTypes.GetAreaOrganizationForGrade_Exercise__Response>(
            '/getAreaOrganizationForGrade_Exercise',
            { gradeId },
        )
        setGradeAreaOrganization(organization.data)
    }

    React.useEffect(() => {
        if (selectedGrade != null) {
            setGradeAreaOrganization(undefined)
            setSelectedGradeArea(undefined)
            setExistingOrNewTest(undefined)

            getAreaOrganizationForGrade(selectedGrade.id)
        }
    }, [selectedGrade])

    const renderGradeOrganization = (organization: AreaOrganizationForGrade) => {
        if (organization.length === 0) {
            return <h3>The grade has no organization yet</h3>
        } else {
            return <div>
                <div className="rendered-isbn-organization">
                    {_.sortBy(organization, (it) => it.order_number)
                        .map(area => {
                            return <div key={area.areaId} style={{ marginLeft: `${area.deep_level * 10 + 10}px`, marginBottom: '15px' }}>
                                <div
                                    style={{ color: selectedGradeArea?.areaId === area.areaId ? 'green' : 'grey' }}
                                    onClick={() => existingOrNewTest === 'new test' ? setSelectedGradeArea({ area_name: area.area_name, areaId: area.areaId }) : null}
                                >
                                    {area.area_name} <span style={{ color: "gray" }}>({area.order_number})</span>
                                </div>
                            </div>
                        })}
                </div>
            </div>
        }
    }

    const maybeRenderNewOrExistingTest = () => {
        check(selectedGrade != null && gradeAreaOrganization != null, 'dgadftaRSFCV')

        if (existingOrNewTest == null) {
            return null
        } else if (existingOrNewTest === 'existing tests') {
            return <GetTests
                selectedGrade={selectedGrade}
                gradeAreaOrganization={gradeAreaOrganization}
            />
        } else if (existingOrNewTest === 'new test') {
            return <CreateTest
                selectedGrade={selectedGrade}
                selectedGradeArea={selectedGradeArea}
            />
        } else {
            assertNever(existingOrNewTest)
        }
    }

    return <>
        <div style={{ marginTop: '20px', border: '2px solid green', padding: '20px' }}>
            <FormGroup>
                <Label for="difficulty">Chose grade</Label>
                <UncontrolledDropdown id="difficulty">
                    <DropdownToggle caret outline>
                        {selectedGrade ? `${selectedGrade.grade_in_local}` : "Chose the grade"}
                    </DropdownToggle>
                    <DropdownMenu>
                        {_.sortBy(gradesForSubject.grades, (it) => it.order_number)
                            .map((grade, index) => <DropdownItem
                                key={index}
                                onClick={() => setSelectedGrade(grade)}
                            >
                                {grade.grade_in_local}
                            </DropdownItem>)}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </FormGroup>
            {gradeAreaOrganization && renderGradeOrganization(gradeAreaOrganization)}
        </div>
        {selectedGrade != null && gradeAreaOrganization != null &&
            <>
                <div className='mg-t-20'>
                    <span className='span-btn'
                        onClick={() => {
                            setExistingOrNewTest('existing tests')
                            setSelectedGradeArea(undefined)
                        }}
                    >
                        Existing tests
                    </span>
                    <span className='span-btn' onClick={() => setExistingOrNewTest('new test')}>Create new test</span>
                </div>
                {maybeRenderNewOrExistingTest()}
            </>
        }
    </>
}
