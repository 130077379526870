import React from 'react'
import { Modal } from 'reactstrap'

export type ModalContentProps<Params> = {
    closeModal: () => void
    params: Params
}

export function useModal<Params>(ModalContent: React.ComponentType<ModalContentProps<Params>>) {
    const [state, setState] = React.useState<Params>()

    const show = (params: Params) => {
        setState(params)
    }

    const close = () => {
        setState(undefined)
    }

    const render = () => {
        if (state == null) {
            return null
        } else {
            return <Modal
                isOpen={state != null}
                toggle={close}
                style={{ maxHeight: "100vh", maxWidth: "96%" }}
            >
                <ModalContent
                    params={state}
                    closeModal={close}
                />
            </Modal>
        }
    }

    return {
        show,
        close,
        render,
    }
}
