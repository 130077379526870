import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { AppContext } from '../contexts/AppContext'
import { queryServer } from "../utils/queryServer"
import { Col, Row, Button, Form, FormGroup, Input, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { SelectedChat } from "./ChatsPage"
import { getProfileImageURL, onProfileImageError } from "../Header/Header"
import { useLocalLanguage } from '../utils/ServiceLocal'
import { RequestResponseTypes, UserBasics } from '../utils/Common'
import { check } from '../utils/utilFunctions'

type Props = {
    modalIsOpen: boolean
    toggleModal: () => void
    selectChat: (selectedChat: SelectedChat) => void
}

// ........ This modal gives warning ........ fix this ........
export const FindFriendModal = (props: Props) => {
    const [usersFirstName, setUsersFirstName] = React.useState('')
    const [usersLastName, setUsersLastName] = React.useState('')
    const [groupChatName, setGroupChatName] = React.useState('')
    const [foundMembers, setFoundMembers] = React.useState<UserBasics[]>([])
    const [addedMembersForGroup, setAddedMembersForGroup] = React.useState<UserBasics[]>([])
    const [foundMembersError, setFoundMembersError] = React.useState<null | string>(null)

    const [activeTab, setActiveTab] = React.useState<'privateChat' | 'groupChat'>('privateChat')

    const { inLocalLanguage } = useLocalLanguage()

    const {
        gMyId,
        gFirstname,
        gLastname,
        gCountryId: gCountry
    } = React.useContext(AppContext)

    const toggle = (tab: 'privateChat' | 'groupChat') => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const findUsers = async () => {
        try {
            setFoundMembersError(null)
            setFoundMembers([])
            const response = await queryServer<RequestResponseTypes.FindUsers__Request, RequestResponseTypes.FindUsers__Response>(
                '/findUsers',
                {
                    firstname: usersFirstName,
                    lastname: usersLastName,
                },
            )

            setFoundMembers(response.users)
        } catch (e: any) {
            setFoundMembersError(e.message ?? 'Unknown error')
        }
    }

    const chooseMemberHandler = (foundUser: UserBasics) => {
        if (activeTab === 'privateChat') {
            const memberMe: UserBasics = {
                firstname: gFirstname,
                lastname: gLastname,
                id: gMyId,
            }

            const chatIdAndChatMembers: SelectedChat = {
                type: 'check_existence',
                members: [memberMe, foundUser],
                details: {
                    type: 'private'
                },
            }

            props.selectChat(chatIdAndChatMembers)
            props.toggleModal()
        } else {
            // toggle user
            let newAddedMembersForGroup: UserBasics[]
            if (addedMembersForGroup.find(it => it.id === foundUser.id)) {
                newAddedMembersForGroup = addedMembersForGroup.filter(it => it.id !== foundUser.id)
            } else {
                newAddedMembersForGroup = addedMembersForGroup.concat([foundUser])
            }

            setAddedMembersForGroup(newAddedMembersForGroup)
        }
    }

    const mutualPart = () => {
        return <div style={{ margin: "15px" }}>
            <Form>
                <Row form>
                    <Col md={4}>
                        <FormGroup>
                            <Input
                                type="text"
                                placeholder="Ime"
                                onChange={e => setUsersFirstName(e.target.value)}
                                value={usersFirstName}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Input
                                type="text"
                                placeholder="Prezime"
                                onChange={e => setUsersLastName(e.target.value)}
                                value={usersLastName}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <Button
                            disabled={usersFirstName === "" && usersLastName === ""}
                            onClick={findUsers}
                        >
                            Find
                        </Button>
                    </Col>
                </Row>
            </Form>
            {foundMembersError && <p>{foundMembersError}</p>}
            {foundMembers.length > 0 && <div className="list-of-found-friends">
                <div className="col-lg-12">
                    <div className="list-group list-group-user">
                        {foundMembers.map(foundUser => <div
                            className="list-group-item"
                            key={foundUser.id}
                        >
                            <img src={getProfileImageURL(foundUser.id)} onError={onProfileImageError} alt="profile image" />
                            <div className="user-name-address">
                                <p>{foundUser.firstname} {foundUser.lastname}</p>
                                <span>Clemson, CA</span>
                            </div>
                            <div className="user-btn-wrapper">
                                <span
                                    className="accept-friend add-friend"
                                    onClick={() => chooseMemberHandler(foundUser)}
                                >
                                    {activeTab === 'privateChat' ? inLocalLanguage('Chose') : 'Toggglee'}
                                </span>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>}
        </div>
    }

    const partForGroupTab = () => {
        let groupMembers
        if (addedMembersForGroup.length > 0) {
            groupMembers = <div style={{ margin: "10px" }}>
                {addedMembersForGroup.map(member => <span
                    key={member.id}
                    style={{ margin: "10px", padding: "5px", border: "1px solid gray" }}
                >
                    {member.firstname} {member.lastname}
                </span>)}
            </div>
        } else {
            groupMembers = <div style={{ margin: "10px" }}>No added users</div>
        }

        return <div>
            {groupMembers}
            <span
                style={{ padding: "5px", border: "1px solid blue" }}
                onClick={() => createGroupChatHandler()}
            >
                OK
            </span>
        </div>
    }

    const createGroupChatHandler = () => {
        const memberMe: UserBasics = {
            firstname: gFirstname,
            lastname: gLastname,
            id: gMyId
        }

        const chatMembers = [memberMe, ...addedMembersForGroup]

        check(chatMembers.length > 1, 'podsa9989vdiiI')

        const chatIdAndChatMembers: SelectedChat = {
            type: 'check_existence',
            members: chatMembers,
            details: {
                type: 'group',
                createdByUser: memberMe.id,
                groupName: groupChatName,
            },
        }

        props.selectChat(chatIdAndChatMembers)
        props.toggleModal()
    }

    return <Modal
        isOpen={props.modalIsOpen}
        toggle={props.toggleModal}
        className="find-friend-message-modal"
    >
        <ModalHeader toggle={props.toggleModal}>Find user</ModalHeader>
        <ModalBody>
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === 'privateChat' })}
                            onClick={() => { toggle('privateChat') }}
                        >
                            1-1 chat
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === 'groupChat' })}
                            onClick={() => { toggle('groupChat') }}
                        >
                            Group chat
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="privateChat">
                        {mutualPart()}
                    </TabPane>
                    <TabPane tabId="groupChat">
                        <Form className="mg-t-20">
                            <Row form>
                                <Col md={12}>
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            placeholder="Ime grupe"
                                            onChange={e => setGroupChatName(e.target.value)}
                                            value={groupChatName}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                        {mutualPart()}
                        {partForGroupTab()}
                    </TabPane>
                </TabContent>
            </div>
        </ModalBody>
    </Modal>
}
