import React from 'react'
import { Popover, PopoverHeader, PopoverBody } from "reactstrap"

type Props = {
    htmlId: string
    renderPopoverBody: (setOpen: React.Dispatch<React.SetStateAction<boolean>>) => React.JSX.Element
    popoverHeaderTitle?: string
}

// TODO (later) : This component can be improved a lot. E.g.:
// add styles through params/use global ID if htmlId is not provided maybe etc.
export const Popover1 = ({
    htmlId,
    renderPopoverBody,
    popoverHeaderTitle,
}: Props) => {
    const [open, setOpen] = React.useState(false)

    const elementId = `id-${htmlId}`

    return <>
        <div
            className='more-btn-1'
            id={elementId}
            onClick={(e) => {
                setOpen(true)
                e.stopPropagation()
            }}
        >
            <i className="icon ion-more" />
        </div>
        <Popover
            target={elementId}
            fade={false}
            trigger="legacy"
            isOpen={open}
            toggle={() => setOpen(false)}
        >
            {popoverHeaderTitle && <PopoverHeader>
                {popoverHeaderTitle}
            </PopoverHeader>}

            <PopoverBody>
                {renderPopoverBody(setOpen)}
            </PopoverBody>
        </Popover>
    </>
}
