import React from 'react'
import { IsbnParsed, RequestResponseTypes } from '../utils/Common'
import { queryServer } from '../utils/queryServer'

export const useAllowedIsbns = () => {
    const [allIsbns, setAllIsbns] = React.useState<IsbnParsed[]>()

    React.useEffect(() => {
        getAllowedIsbns()
    }, [])

    const getAllowedIsbns = async () => {
        const response = await queryServer<RequestResponseTypes.GetAllIsbns__Request, RequestResponseTypes.GetAllIsbns__Response>(
            '/getAllowedIsbns',
            undefined,
        )

        setAllIsbns(response.data)
    }

    return allIsbns
}
