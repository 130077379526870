import React from 'react'
import { Container } from '../utils/Container'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSavedCollectionsMetadata } from "./useSavedCollectionsMetadata"
import { useModal } from '../utils/useModal'
import { DeleteCollectionModal } from './DeleteCollectionModal'
import { Loader } from '../utils/Loader'
import { AppContext } from '../contexts/AppContext'
import { Popover1 } from '../utils/Popover1'
import { capitalizeFirstLetter, check, nowSecondsUTC } from '../utils/utilFunctions'
import { useLocalLanguage } from '../utils/ServiceLocal'
import { EditCollectionModal } from './EditCollectionModal'

export const SavedCollectionsPage = () => {
    const [searchParams] = useSearchParams()

    const userId = searchParams.get("userId")

    let content
    if (userId == null) {
        content = <h3>Unknown user</h3>
    } else {
        content = <SavedCollections userId={userId} />
    }

    return <Container
        title="Saved collection page"
        className="home-page"
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: false,
        }}
    >
        {content}
    </Container>
}

const SavedCollections = ({ userId }: { userId: string }) => {
    const navigate = useNavigate()
    const savedCollections = useSavedCollectionsMetadata(userId)
    const deleteCollectionModal = useModal(DeleteCollectionModal)
    const editCollectionModal = useModal(EditCollectionModal)
    const { gMyId } = React.useContext(AppContext)

    const { inLocalLanguage } = useLocalLanguage()

    if (savedCollections == null) {
        return <Loader />
    }

    let content
    if (savedCollections.totalSavedCollections === 0) {
        if (userId === gMyId) {
            content = <h4>{inLocalLanguage("You dont have saved collections")}</h4>
        } else {
            content = <h4>
                {capitalizeFirstLetter(savedCollections.collectionsOwner.firstname)} {capitalizeFirstLetter(savedCollections.collectionsOwner.lastname)} {inLocalLanguage("doesnt have saved collections")}
            </h4>
        }
    } else {
        content = <>
            <div className="buckets-filter-box">
                <input
                    type="text"
                    placeholder={inLocalLanguage('Find collection')}
                    onChange={e => savedCollections.setTypingKeywords(e.target.value)}
                    value={savedCollections.typingKeywords}
                    style={{ fontSize: "16px" }}
                />
            </div>
            <div>
                {savedCollections.sortedAndFilteredCollections.map(collection => <div
                    key={collection.id}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <div
                        className="bucket-in-list"
                        onClick={() => navigate(`/savedCollection/${collection.id}`)}
                        style={{ position: "relative", paddingRight: "30px" }}
                    >
                        <h3>{collection.collection_title}</h3>
                        <div style={{
                            marginTop: "6px",
                            color: "rgb(134, 139, 161)",
                            fontSize: "78%",
                        }}>{collection.collection_description}</div>
                        {userId === gMyId && <Popover1
                            htmlId={collection.id}
                            renderPopoverBody={(setOpen) => {
                                return <div className='delete-collection-popover-body'>
                                    <div className='option' onClick={(e) => {
                                        editCollectionModal.show({
                                            collectionId: collection.id,
                                            currIsPrivate: collection.is_private,
                                            currCollectionDescription: collection.collection_description,
                                            currCollectionTitle: collection.collection_title,
                                            afterSave: (
                                                collectionId,
                                                newIsPrivate,
                                                newCollectionTitle,
                                                newCollectionDescription,
                                            ) => {
                                                savedCollections.setSavedSortedCollectionsAndOwner(prev => {
                                                    check(prev != null && prev.owner.id === gMyId)

                                                    return {
                                                        owner: prev.owner,
                                                        allCollections_sorted: prev
                                                            .allCollections_sorted
                                                            .map(it => {
                                                                if (it.id !== collectionId) {
                                                                    return it
                                                                } else {
                                                                    return {
                                                                        ...it,
                                                                        is_private: newIsPrivate,
                                                                        collection_title: newCollectionTitle,
                                                                        collection_description: newCollectionDescription,
                                                                        last_updated_ts: nowSecondsUTC(),
                                                                    }
                                                                }
                                                            })
                                                            .sort((a, b) => b.last_updated_ts - a.last_updated_ts)
                                                    }
                                                })
                                            },
                                        })
                                        setOpen(false)
                                        e.stopPropagation()
                                    }}>
                                        <i className='icon ion-edit' style={{ fontSize: "120%" }} /> {inLocalLanguage('Edit')}
                                    </div>
                                    <div className='option' onClick={(e) => {
                                        deleteCollectionModal.show({
                                            collectionId: collection.id,
                                            collectionTitle: collection.collection_title,
                                            setSavedSortedCollectionsAndOwner: savedCollections.setSavedSortedCollectionsAndOwner,
                                            navigateToSavedCollectionsAfterDelete: false,
                                        })
                                        setOpen(false)
                                        e.stopPropagation()
                                    }}>
                                        <i className='icon ion-trash-b' style={{ fontSize: "120%" }} /> {inLocalLanguage("Delete")}
                                    </div>
                                </div>
                            }}
                        />}
                    </div>
                </div>)}
            </div>
            {userId === gMyId && deleteCollectionModal.render()}
            {userId === gMyId && editCollectionModal.render()}
        </>
    }

    return <div className='saved-buckets-list'>
        <div className='mg-b-30' style={{
            color: 'rgb(27, 132, 231)',
        }}>
            <h1>
                {gMyId === userId ? inLocalLanguage("Collections saved by you") : `
                    ${inLocalLanguage("Collections saved by")} ${capitalizeFirstLetter(savedCollections.collectionsOwner.firstname)} ${capitalizeFirstLetter(savedCollections.collectionsOwner.lastname)}
                `}
            </h1>
        </div>
        {content}
    </div>
}
