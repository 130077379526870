import React from 'react'
import { queryServer } from "../utils/queryServer"
import { QueriedComment, RequestResponseTypes, TopCommentOnDetails } from '../utils/Common'
import { CommentWithReplies } from './CommentWithReplies'
import { ENVIRONMENT_CALCULATED } from '../utils/constants'

type Props = {
    topCommentOn: TopCommentOnDetails
    commentOnPreview: string
    children: JSX.Element
    subjectId: string
    className?: string
    initialyQueriedComments: [QueriedComment, QueriedComment[]][] | undefined // [topComment, it'sReplies][]
}

export const TopCommentsWrapper = ({
    topCommentOn,
    commentOnPreview,
    children,
    subjectId,
    className,
    initialyQueriedComments,
}: Props) => {
    // stupid. improve later !!!!!!!!
    const initialyQueriedCommentsMap = new Map((initialyQueriedComments ?? []).map(it => [it[0].commentId, it]))

    const closedForTheFirstTime = React.useRef(false)

    const calcInitialIsOpen = () => {
        if (closedForTheFirstTime.current === false) {
            return initialyQueriedComments != null && initialyQueriedComments.length > 0
        } else {
            return false
        }
    }

    const calcInitialQueriedComments = () => {
        if (closedForTheFirstTime.current === false && initialyQueriedComments != null) {
            if (initialyQueriedComments.length === 0) {
                return undefined
            }

            return initialyQueriedComments.map(it => it[0]).sort((a, b) => a.lastUpdatedTs - b.lastUpdatedTs)
        } else {
            return undefined
        }
    }

    const [isOpen, setIsOpen] = React.useState(calcInitialIsOpen())
    const [commentText, setCommentText] = React.useState('')
    const [queriedTopComments, setQueriedTopComments] = React.useState<QueriedComment[] | undefined>(calcInitialQueriedComments())

    // mislim da i hasOlderComments treba da se vrati na true kada se toggleIsOpen,
    // jer ako brises komentare, onda i ovo treba da se obrise... kasnije proveri i sredi !!!!!!
    const [hasOlderComments, setHasOlderComments] = React.useState(true)

    const [renderLoadNewerComments, setRenderLoadNewerComments] = React.useState(closedForTheFirstTime.current === false && initialyQueriedComments != null && initialyQueriedComments.length > 0)

    React.useEffect(() => {
        if (isOpen) {
            if (initialyQueriedComments != null && closedForTheFirstTime.current === false) {
                // don't query if there are initiallyQueried comments and if it is first open
                // check this logic... !!!!!
            } else {
                getOlderComments(null)
            }
        } else {
            closedForTheFirstTime.current = true
            setRenderLoadNewerComments(false)
            setQueriedTopComments(undefined)
            setCommentText('')
        }
    }, [isOpen])

    const getOlderComments = async (lastReadDocId: string | null) => {
        const comments = await queryServer<RequestResponseTypes.GetComments__Request, RequestResponseTypes.GetComments__Response>(
            '/getComments',
            {
                inId: topCommentOn.onId,
                lastReadDocId,
            },
        )

        // mod 3 because pagination is by 3 on backend. If change on backend, change here too !!!
        if (comments.comments.length > 0 && comments.comments.length % 3 === 0) {
            setHasOlderComments(true)
        } else {
            setHasOlderComments(false)
        }

        setQueriedTopComments(prev => (prev ?? []).concat(comments.comments).sort((a, b) => a.lastUpdatedTs - b.lastUpdatedTs))
    }

    const getNewerComments = async (lastSeenCommentId: string) => {
        const comments = await queryServer<RequestResponseTypes.GetNewerComments__Request, RequestResponseTypes.GetNewerComments__Response>(
            '/getNewerComments',
            {
                inId: topCommentOn.onId,
                lastSeenCommentId,
            },
        )

        // mod 3 because pagination is by 3 on backend. If change on backend, change here too !!! dsa786jgh
        if (comments.comments.length > 0 && comments.comments.length % 3 === 0) {
            setRenderLoadNewerComments(true)
        } else {
            setRenderLoadNewerComments(false)
        }

        setQueriedTopComments(prev => (prev ?? []).concat(comments.comments).sort((a, b) => a.lastUpdatedTs - b.lastUpdatedTs))
    }

    const renderComments = () => {
        let content
        if (queriedTopComments == null) {
            content = <h6>Please wait...</h6>
        } else if (queriedTopComments.length === 0) {
            content = <h6>No comments yet</h6>
        } else {
            content = <div>
                {hasOlderComments && <span
                    style={{ color: 'red' }}
                    onClick={() => getOlderComments(queriedTopComments[0].commentId)}
                >
                    Get older comments
                </span>}

                <div className="comments">
                    {queriedTopComments.map(topComment => {
                        // uzmi initialyQueriedCommentReplies samo ako nije zatvoreno prvi put
                        const initialyQueriedCommentReplies = closedForTheFirstTime.current === false ? initialyQueriedCommentsMap.get(topComment.commentId)?.[1] : undefined
                        const initialyQueriedCommentRepliesChecked = (initialyQueriedCommentReplies == null || initialyQueriedCommentReplies.length === 0) ? undefined : initialyQueriedCommentReplies.sort((a, b) => a.lastUpdatedTs - b.lastUpdatedTs)

                        const initialyLenderLoadNewerCommentsInCommentWithReplies = initialyQueriedCommentRepliesChecked == null ? false : true

                        return <CommentWithReplies
                            key={topComment.commentId}
                            hasReplies={topComment.hasReplies}
                            topCommentAuthor={{
                                firstname: topComment.authorFirstname,
                                lastname: topComment.authorLastname,
                                userId: topComment.authorId,
                            }}
                            subjectId={subjectId}
                            topCommentContent={topComment.content}
                            topCommentId={topComment.commentId}
                            topCommentOnDetails={topCommentOn}
                            topCommentTs={topComment.lastUpdatedTs}
                            initialyQueriedCommentReplies={initialyQueriedCommentRepliesChecked}
                            initialyLenderLoadNewerComments={initialyLenderLoadNewerCommentsInCommentWithReplies}
                        />
                    })}
                </div>
                {renderLoadNewerComments && <span
                    style={{ color: 'red' }}
                    onClick={() => {
                        if (queriedTopComments == null || queriedTopComments.length === 0) {
                            setRenderLoadNewerComments(false)
                        } else {
                            const newestCommentId = queriedTopComments.reduce((max, curr) => {
                                if (curr.lastUpdatedTs > max.lastUpdatedTs) {
                                    return curr
                                } else {
                                    return max
                                }
                            }, queriedTopComments[0]).commentId
                            getNewerComments(newestCommentId)
                        }
                    }}
                >
                    Get newer comments
                </span>}
            </div>
        }

        return <div className="queried-comments">
            {content}
        </div>
    }

    const setTopComment = async () => {
        const result = await queryServer<RequestResponseTypes.SetComment__Request, RequestResponseTypes.SetComment__Response>(
            '/setComment',
            {
                content: commentText,
                comment_on_details: topCommentOn,
                comment_in_id: topCommentOn.onId,
                comment_on_subject_id: subjectId,
                comment_on_preview: commentOnPreview,
                response_to_user: null,
            },
        )

        setQueriedTopComments(prev => (prev ?? []).concat(result).sort((a, b) => a.lastUpdatedTs - b.lastUpdatedTs))
        setCommentText('')
    }

    return <div className={`${className ?? ''}`}>
        {children}
        {ENVIRONMENT_CALCULATED === "dev" && <div className="comment-box">
            {isOpen && <div className="card card-quick-post mg-t-5 show-more-box">
                {renderComments()}
                <div>
                    <div className="form-group">
                        <input
                            autoComplete="off"
                            type="text"
                            placeholder="Type a comment here..."
                            onChange={e => setCommentText(e.target.value)}
                            value={commentText}
                        />
                    </div>
                    <div>
                        <button
                            className="btn btn-purple"
                            onClick={setTopComment}
                            disabled={commentText.length === 0}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div>}
            <div
                className="show-more-eot-part-dots-new"
                onClick={() => setIsOpen(!isOpen)}
            >
                ...
            </div>
        </div>}
    </div>
}