import React from 'react'
import { Container } from "../utils/Container"
import {
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from 'reactstrap'
import { nanoid } from 'nanoid'
import { check, checkNotNull } from "../utils/utilFunctions"
import { queryServer } from "../utils/queryServer"
import { RequestResponseTypes } from '../utils/Common'
import * as _ from "lodash"

type IsbnArea = {
    id: string
    area_name: string
    order_number: number
    parent_id: string | null
    deep_level: number
    descriptionForAddingIsbnArea: string
}

export const AddIsbnPage = () => {
    const [isbn, setIsbn] = React.useState(-1)
    const [bookTitle, setBookTitle] = React.useState('')
    const [bookAuthor, setBookAuthor] = React.useState('')
    const [additionalDescription, setAdditionalDescription] = React.useState('')
    const [publisher, setPublisher] = React.useState('')
    const [nPages, setNPages] = React.useState(-1)
    const [nExercises, setNExercises] = React.useState(-1)
    const [isbnAreas, setIsbnAreas] = React.useState<IsbnArea[] | undefined>(undefined)
    const [newIsbnArea, setNewIsbnArea] = React.useState<IsbnArea | undefined>(undefined)

    const saveIsbn = async () => {
        try {
            check(isbn > -1, 'isbn not good')
            check(bookTitle !== '', 'bookTitle not good')
            check(isbnAreas != null && isbnAreas.length > 0, 'isbnAreas not good')

            await queryServer<RequestResponseTypes.SaveIsbn__Request, RequestResponseTypes.SaveIsbn__Response>(
                '/saveIsbn',
                {
                    isbnMetadata: {
                        isbn,
                        book_title: bookTitle,
                        publisher: publisher.length > 0 ? publisher : null,
                        n_exercises: nExercises > -1 ? nExercises : null,
                        n_pages: nPages > -1 ? nPages : null,
                        additional_description: additionalDescription.length > 0 ? additionalDescription : null,
                        book_author: bookAuthor.length > 0 ? bookAuthor : null,
                    },
                    isbnAreas,
                },
            )

            // to prevent saving again, and to be able to see added isbn
            location.reload()
        } catch (err) {
            console.log(err)
        }
    }

    const renderIsbnAreas = (isbnAreas: IsbnArea[]) => {
        return _.sortBy(isbnAreas, (isbnArea) => isbnArea.order_number)
            .map(area => {
                return <div
                    key={area.id}
                    style={{ marginLeft: `${area.deep_level * 10 + 10}px` }}
                >
                    <span style={{ marginRight: '20px' }}>
                        <span style={{ color: 'black', marginRight: '15px' }}>
                            {area.area_name}
                        </span>
                        (order number: {area.order_number}, parent: {area.parent_id == null ? 'top-level' : checkNotNull(isbnAreas.find(it => it.id === area.parent_id), 'ids8iuuvv').area_name})
                    </span>
                    <span style={{ color: 'blue' }} onClick={() => {
                        setNewIsbnArea({
                            id: nanoid(),
                            area_name: '',
                            order_number: -1,
                            deep_level: area.deep_level + 1,
                            parent_id: area.id,
                            descriptionForAddingIsbnArea: `Add subarea in "${area.area_name}"`,
                        })
                    }}>
                        Add subarea
                    </span>
                </div>
            })
    }

    const renderAddNewAreaForm = () => {
        return <div style={{ border: "2px solid pink", padding: "10px", marginTop: "20px", marginBottom: "20px" }}>
            <h4>{newIsbnArea!.descriptionForAddingIsbnArea}</h4>
            <FormGroup>
                <Label>Area name</Label>
                <Input
                    type="text"
                    placeholder="area..."
                    onChange={(e) => setNewIsbnArea({
                        ...newIsbnArea!,
                        area_name: e.target.value
                    })}
                    value={newIsbnArea!.area_name}
                />
            </FormGroup>
            <FormGroup>
                <Label>Order number</Label>
                <Input
                    type="number"
                    placeholder="order number..."
                    onChange={(e) => setNewIsbnArea({
                        ...newIsbnArea!,
                        order_number: +e.target.value
                    })}
                    value={newIsbnArea!.order_number}
                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                />
            </FormGroup>
            <Button onClick={() => {
                check(newIsbnArea != null, 'igqv')
                const okIsbnArea = newIsbnArea.area_name.length > 0 && newIsbnArea.order_number > -1

                if (okIsbnArea) {
                    setIsbnAreas([...(isbnAreas || []), newIsbnArea])
                    setNewIsbnArea(undefined)
                } else {
                    console.log('djsf66')
                }
            }}>
                Ok
            </Button>
            <Button onClick={() => setNewIsbnArea(undefined)}>Cancel</Button>
        </div>
    }

    return <Container
        title={`Add ISBN. Contributor`}
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: true,
        }}
        className="add-isbn-page"
    >
        <div style={{ marginTop: '10px', marginBottom: '50px' }}>
            <Form>
                <FormGroup>
                    <Label>Isbn</Label>
                    <Input
                        type="number"
                        placeholder="Enter isbn..."
                        onChange={(e) => setIsbn(+e.target.value)}
                        value={isbn}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Book title</Label>
                    <Input
                        type="text"
                        placeholder="Enter book title..."
                        onChange={(e) => setBookTitle(e.target.value)}
                        value={bookTitle}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>[OPTIONAL] Book author</Label>
                    <Input
                        type="text"
                        placeholder="Enter book author..."
                        onChange={(e) => setBookAuthor(e.target.value)}
                        value={bookAuthor}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>[OPTIONAL] Publisher</Label>
                    <Input
                        type="text"
                        placeholder="Enter publisher..."
                        onChange={(e) => setPublisher(e.target.value)}
                        value={publisher}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>[OPTIONAL] Additional description</Label>
                    <Input
                        type="text"
                        placeholder="Additional description..."
                        onChange={(e) => setAdditionalDescription(e.target.value)}
                        value={additionalDescription}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>[OPTIONAL] Number of exercises in the book</Label>
                    <Input
                        type="number"
                        placeholder="n exercises..."
                        value={nExercises}
                        onChange={e => setNExercises(+e.target.value)}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="keywords">[OPTIONAL] Number of pages in the book</Label>
                    <Input
                        type="number"
                        placeholder="n pages..."
                        value={nPages}
                        onChange={e => setNPages(+e.target.value)}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                    />
                </FormGroup>
                <hr />
                <Button
                    onClick={() => setNewIsbnArea({
                        id: nanoid(),
                        area_name: '',
                        order_number: -1,
                        parent_id: null,
                        deep_level: 0,
                        descriptionForAddingIsbnArea: 'Add area in top-level'
                    })}
                >
                    Dodaj top-level oblast
                </Button>
                {newIsbnArea != null && renderAddNewAreaForm()}
                <div>
                    {isbnAreas != null && renderIsbnAreas(isbnAreas)}
                </div>
            </Form>
        </div>
        <hr />
        <Button onClick={saveIsbn}>Save Isbn</Button>
    </Container>
}
