import React from 'react'
import { AppContext } from '../contexts/AppContext'
import { queryServer } from './queryServer'
import { collectionRef } from '../firestore'
import { CommentDoc } from './Common'
import { check } from './utilFunctions'
import { ChatDocWithDefinedLastMessage } from '../MessagesPage/ChatsPage'
import { onSnapshot } from 'firebase/firestore'
import { limit, orderBy, query, where } from 'firebase/firestore'
import { ENVIRONMENT_CALCULATED } from './constants'

const testtt = (str: string) => {
    console.log(str)

    queryServer<{ s: string }, null>(
        '/testtt',
        { s: str },
    )
}

export const useNotifications = () => {
    const {
        gLastNotificationsCheck,
        gSetNotification,
        gLastMessagesCheck,
        gSetMessageNotification,
        gSetAllCommentsNotification,
        gMyId,
        gFirstname,
        gLastname,
        gLastAllComentsCheck,
        gStatus,
        gLeadModeratorForSubjects,
    } = React.useContext(AppContext)

    const LIMIT = 1

    const notificationListener = () => {
        const myCollectionRef = collectionRef<{ lastUpdatedTs: number }>(`NotificationPerUser/${gMyId}/Notification`)
        const myQuery = query(
            myCollectionRef,
            orderBy("lastUpdatedTs", 'desc'),
            limit(LIMIT),
        )

        const unsubscribe = onSnapshot(
            myQuery,
            (querySnapshot) => {
                if (querySnapshot.size > 0) {
                    check(querySnapshot.size === LIMIT, 'cxkj89a')
                    const lastNotifications = querySnapshot.docs.map(it => it.data())
                    gSetNotification(lastNotifications[0].lastUpdatedTs > gLastNotificationsCheck)
                }

                console.log('Notification listener is active...')
            },
            (err) => {
                console.log('Error 2yQhdsa7zt')
                console.log(err)
            },
        )

        return unsubscribe
    }

    // newMessageListener's assignment is to set red dot on message badge if needed
    const newMessageListener = () => {
        const myCollectionRef = collectionRef<ChatDocWithDefinedLastMessage>("Chat")
        const myQuery = query(
            myCollectionRef,
            where('lastMessage.shouldBeSeenByUsers', 'array-contains', gMyId),
            // result should have lastMessage != null, because orderBy also acts like a filter
            orderBy('lastMessage.timeSent', 'desc'),
            limit(LIMIT),
        )

        const unsubscribe = onSnapshot(
            myQuery,
            (querySnapshot) => {
                if (querySnapshot.size > 0) {
                    // since orderBy (.orderBy('lastMessage.timeSent', 'desc')) also acts as a
                    // filter (it ignores items with null values), the lastMessage should exist.
                    check(querySnapshot.size === LIMIT, 'asdoi988')
                    const chat = querySnapshot.docs.map(it => it.data() as ChatDocWithDefinedLastMessage)[0]
                    // check just to be sure
                    check(chat.lastMessage != null, 'poInngY8')
                    gSetMessageNotification(chat.lastMessage.timeSent > gLastMessagesCheck)
                }

                console.log('New Message Listener is active...')
            },
            (err) => {
                console.log('kv877ujh')
                console.log(err)
            },
        )

        return unsubscribe
    }

    // newCommentListener is only for lead moderators. And it will listen
    // for new comments (for now both top-comments and comment-replies)
    const newCommentListener = () => {
        if (gStatus === 'leadModerator') {
            if (gLeadModeratorForSubjects.length === 0 || gLeadModeratorForSubjects.length > 10) {
                console.log('[SILENT ERROR DSAC98csaj]::: bad gLeadModeratorForSubjects ::: ', gLeadModeratorForSubjects)
                return null
            }

            const myCollectionRef = collectionRef<CommentDoc>("Comment")
            const myQuery = query(
                myCollectionRef,
                where('comment_on_subject_id', 'in', gLeadModeratorForSubjects),
                orderBy('last_updated_ts', 'desc'),
                limit(LIMIT),
            )

            const unsubscribe = onSnapshot(
                myQuery,
                (querySnapshot) => {
                    if (querySnapshot.size > 0) {
                        check(querySnapshot.size === LIMIT, 'oiu78uij')
                        const lastNotifications = querySnapshot.docs.map(it => it.data())
                        gSetAllCommentsNotification(lastNotifications[0].last_updated_ts > gLastAllComentsCheck)
                    }

                    console.log('New comments listener is active...')
                },
                (err) => {
                    console.log('Error cs7yuadsyugn')
                    console.log(err)
                },
            )

            return unsubscribe
        } else {
            return null
        }
    }

    React.useEffect(() => {
        testtt(`set notification listeners for user ${gLastname} ${gFirstname}`)

        const detachNotificationListener = notificationListener()
        const detachNewMessageListener = newMessageListener()
        const detachNewCommentListener = newCommentListener()

        const unsubscribe = () => {
            testtt(`unsubsubscribe notification listeners for user ${gLastname} ${gFirstname}`)
            detachNotificationListener()
            detachNewMessageListener()
            if (detachNewCommentListener != null) {
                detachNewCommentListener()
            }
        }

        window.addEventListener("beforeunload", unsubscribe)

        return () => {
            unsubscribe()
            window.removeEventListener("beforeunload", unsubscribe)
            if (ENVIRONMENT_CALCULATED === 'prod') {
                // https://github.com/facebook/react/issues/24467#issuecomment-1113273743
                location.reload()
            }
        }
    }, [])
}
