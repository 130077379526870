import React from 'react'
import { Container } from "../utils/Container"
import { SolveEot } from './SolveEot'

export const SolveTheoryPage = () => {
    return <Container
        title='Solve theory'
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: false,
        }}
    >
        <SolveEot solve="theory" />
    </Container>
}
